.block {
  height: 100vh;
  width: 100%;
  position: relative;
}

.__bg_img {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../static/images/wave-bright-red.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
  }
}

.__bg_img_content {
  z-index: 1;
}