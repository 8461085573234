@use '../../style_imports/layout' as *;

.block {
  @media (min-width: $md_breakpoint) {
    max-width: 25rem;
  }
}

.color {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.item_is_clickable {
  cursor: pointer;
}

.spacer_row {
  height: 0.25rem;
}

.cell {
  vertical-align: top;
}

.cell__total {
  border-top: 1px solid;
  padding-top: 0.25rem;
}


