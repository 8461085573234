/* overriding table styles from react-table.scss */

@use '../style_imports/_colours' as *;

.ReactTable {
  border: none;
  .rt-table {
    overflow: initial !important;
  }

  .rt-thead {
    &.-header {
      box-shadow: none;
      border-bottom: 1.5px solid $light_grey_border;
    }

    .rt-th {
      // For some reason:
      //     body has overflow 'auto'
      //     header has overflow 'visible'
      // and this can result in the header having 22px extra
      // scroll width. I'm not entirely sure why.

      // So....
      // We override this here, and set header overflow to 'hidden'
      // i.e. setting header header overflow 'auto' would give
      // scroll bars on each field.
      // This is relied on by ReactTableWrapper.js
      overflow: hidden !important;
      border-right: none;

      font-size: 16px;
      font-weight: 700;
      padding: .75rem;

      &.no-sort-mark, &.-sort-asc, &.-sort-desc {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }

  .rt-resizer {
    right: -22px;
    width: 27px;
  }

  .rt-tbody {
    overflow-x: auto;    // allow horizontal scrollbar in body
    overflow-y: hidden;  // hide vertical scrollbar in body (fixes IE11 problem)

    .rt-tr-group {
      border-bottom: none;

      .show-on-hover, .show-on-hover-flex {
        display: none;
      }

      &:hover {
         // elements set to 'display: none' will show when their row is hovered
        .show-on-hover {
          display: inline;
        }
        .show-on-hover-flex {
          display: flex;
        }
      }
    }

    .rt-td {
      border: none;
      border-bottom: 1px solid $light_grey_border;
      white-space: normal;
      padding: .5rem .75rem;
      font-size: 16px;
      line-height: 1.8rem;
      color: $grey_text_1;
      font-weight: 400;
    }
  }

  .rt-tr {
    &:hover {
      .rt-td {
        background: $light_blue_bg_1;
      }
    }
  }

  .row-selected {
    &:hover {
      .rt-td {
        background-color: $list_item_selected_hover;
      }
    }
  }

  &.-striped {

    .rt-tr {
      &.-even {
        background: $grey_bg_2;

        .rt-td {
          background: $grey_bg_2;
        }
      }

      &.-odd {
        background: $lightest_bg;

        .rt-td {
          background: $lightest_bg;
        }
      }
    }
  }

  .rt-noData {
    // don't overlap with table headers
    top: 6rem !important;
  }
}

.pagination-bottom {
  margin-top: 1rem;
}

.pagination-top {
  margin-bottom: 1rem;
}

.patent_family_list.ReactTable .rt-th .rt-resizable-header-content {
  overflow: visible !important;
}

/*
  overrides from BaseTable.js
*/
.thumbnail_view {
  .rt-table, .rt-tbody {
    overflow: hidden !important;
  }

  .rt-tbody .rt-tr-group {
    height: 1.5rem;
  }
}
