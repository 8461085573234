@use '../../style_imports/colours' as *;

$icon_height: 10px;
$space_between_icons: .25rem;

.header_wrapper {
  overflow: visible;
  height: 1.5rem;
  flex-grow: 2;
}

.header_wrapper__no_sort {
  cursor: default;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &__sortable {
    cursor: pointer;
  }
}

.sort_icon_container {
  cursor: pointer;
  margin-left: .25rem;
  height: calc(($icon_height * 2) + $space_between_icons);
}

.sort_asc_wrapper {
  margin-bottom: calc($space_between_icons / 2);
}

.sort_desc_wrapper {
  margin-top: calc($space_between_icons / 2);
}


.icon_current {
  display: flex;
  align-items: center;

  .sort_icon_container:hover & {
    display: none;
  }
}

.icon_new {
  display: none;

  .sort_icon_container:hover & {
    display: flex;
    align-items: center;
    color: $link_text_hover;
  }
}

.icon {
  height: $icon_height;
  font-size: 10px;
  color: $link_text;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.column_header {
  :global {
    .-sort-asc {
      box-shadow: none !important;

      .sort-asc-on {
        display: block !important;
      }

      .sort-desc-on {
        display: none !important;
      }

      .sort-asc-off {
        display: none !important;
      }

      .sort-desc-off {
        display: block  !important;
      }
    }

    .-sort-desc {
      box-shadow: none !important;

      .sort-asc-on {
        display: none !important;
      }

      .sort-desc-on {
        display: block !important;
      }

      .sort-asc-off {
        display: block !important;
      }

      .sort-desc-off {
        display: none !important;
      }
    }
  }
}