@use '../../style_imports/_colours' as *;

.status_bar {
  color: $dark_text;
  font-size: smaller;
  text-align: center;
  vertical-align: text-bottom;
  height: 1.5rem;
  border-radius: 1rem;
  border: solid 2px $dark_text;
  display: inline-block;
  width: 7.5rem;
}

.status_bar_running {
  border-color: $progress_running;
}

.status_bar_not_started {
  border-color: $progress_not_started;
}

.status_bar_completed {
  border-color: $progress_complete;
}

.progress_table {
  table-layout: fixed;
  color: $blue_grey_text_1;
  thead th {
    border-top: none;
    border-bottom: 1px solid $light_grey_border;
  }
  tbody td {
    border: none;
  }
}

.classifier_task_details {
  color: $blue_grey_text_1;
}