@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.apps_menu_toggle {
  height: $header_height_xl;
  color: $lightest_text;
  font-size: 24px;

  &:hover {
    color: $link_text_hover;
  }
}

.apps_menu_toggle__active {
  background: $menu_bg;
  color: $link_text_hover !important;
}

.apps_menu {
  background: $menu_bg;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  z-index: 1200;
}

.apps_menu_divider {
  background-color: $link_text;
  width: 1px;
}

.apps_menu_link {
  font-weight: 300;
  font-size: 21px;

  &:hover {
    text-decoration: none;
    color: $link_text_hover !important;
  }
}

.apps_menu_link__small {
  font-weight: 300;
  font-size: 15px;

  white-space: nowrap;
  &:hover {
    text-decoration: none;
    color: $link_text_hover !important;
  }
}
