@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;
@use '../../style_imports/family_details_view_layout' as *;

.block {
  color: $grey_text_1;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.two_column_container {
  border-bottom: 1px solid $family_view_border;
}

.one_column_container {
  border-left: 1px solid $family_view_border;
  border-right: 1px solid $family_view_border;
  border-bottom: 1px solid $family_view_border;
}

$tiles_width_md: 15rem;
$tiles_width_lg: 15rem;
$tiles_width_xl: 18rem;
$tiles_width_xxl: 20rem;

.tiles_wrapper {
  width: 100%;
  border-left: 1px solid $family_view_border;
  border-right: 1px solid $family_view_border;

  @media (min-width: $md_breakpoint) {
    width: $tiles_width_md;
  }

  @media (min-width: $xl_breakpoint) {
    width: $tiles_width_xl;
  }

  @media (min-width: $xxl_breakpoint) {
    width: $tiles_width_xxl;
  }
}

.details_wrapper {
  width: 100%;
  border-right: 1px solid $family_view_border;
  border-left: 1px solid $family_view_border;

  @media (min-width: $md_breakpoint) {
    border-right: none;
  }

  @media (min-width: $md_breakpoint) {
    width: calc(100% - #{$tiles_width_md});
  }

  @media (min-width: $xl_breakpoint) {
    width: calc(100% - #{$tiles_width_xl});
  }

  @media (min-width: $xxl_breakpoint) {
    width: calc(100% - #{$tiles_width_xxl});
  }
}

.xml_wrapper {
  background-color: $grey_bg_2;
  border: 4px solid $family_view_border;
  border-radius: $border_radius_small;
}

.top_wrapper {
  @extend .one_column_container;
  border-top: 1px solid $family_view_border;
  background-color: $grey_bg_2;
  height: $top_wrapper_height;
  z-index: calc($label_controls_z_index + 2);
  position: sticky;
}

.navigation_wrapper {
  @extend .one_column_container;
  top: $top_wrapper_height;
  background-color: $lightest_bg;
  height: $nav_wrapper_height;
  z-index: calc($label_controls_z_index + 1);
  position: sticky;
}

.label_controls_top {
  background-color: $lightest_bg;
  z-index: $label_controls_z_index;

  @media screen and (min-width: $xl_breakpoint) and (orientation: landscape),
  screen and (min-width: $md_breakpoint) and (orientation: portrait) {
    position: sticky;
    top: calc($top_wrapper_height + $nav_wrapper_height);
  }

}
