.wrapper {
  height: 1275px;
  overflow-x: scroll;
  text-align: unset;
}

.inner_wrapper {
  width: 900px;
}

