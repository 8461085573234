@use '../../style_imports/colours' as *;

.content {
  border: 2px solid $bright_blue_border;
  border-radius: 24px;

  font-size: 12px;
  font-weight: 700;
  line-height: 18px;

  height: 1.3rem;
  color: $blue_grey_text_3;

  &__deprecated {
    border-color: $attention;
  }
}

 .__deprecated {
    color: $attention;
}