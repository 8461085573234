@use '../../../style_imports/colours' as *;
@use '../../../style_imports/layout' as *;
@use '../../widgets/Button.module' as *;

.button {
  padding: .125rem .5rem;
}

.menu {
  min-width: 21rem;
  width: auto;
}

.button_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 5.5rem;
  text-align: left;
}

.slider_label {
  min-width: 4rem;
}

.sub_container {
  min-width: 6.5rem;
}

.sub {
  min-width: 6.5rem;
}

.dropdown_btn {
  @extend .btn;
  @extend .__sm;

  color: $dark_text;
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: 14px !important;
  font-weight: 400 !important;

  &__disabled {
    color: $disabled_txt;
    background-color: transparent;
    cursor: default;
  }

  &__enabled:hover {
    background-color: $light_blue_bg_1 !important;
    color: $dark_text;
  }

  &__active, &__active:hover {
    background-color: $item_active !important;
    fill: $lightest_text !important;
    color: $lightest_text !important;
  }
}

.custom_score_label {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}
