@use '../../style_imports/colours' as *;

.icon {
  font-size: 16px;
  color: $disabled_txt;
}

.__found {
  color: $match_found;
}

.__not_found {
  color: $match_not_found;
}

.__ambiguous {
  color: $match_ambiguous;
}