@use '../../style_imports/colours' as *;

.icon {
  cursor: pointer;
  font-size: medium;
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}

.icon__small {
  font-size: small;
}

.icon__disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.page_label {
  user-select: none;
}

.toggle_icon {
  padding: 0 0.5rem;
}