@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.block {
  height: calc(100vh - (#{$header_height_xl}));
}

.breadcrumbs_wrapper {
  border-bottom: 1px solid $light_grey_border;
  background-color: $lightest_bg;
}

$breadcrumbs_height: 2rem;
$breadcrumbs_margin: .5rem;

.breadcrumbs {
  height: $breadcrumbs_height;
  margin: $breadcrumbs_margin 0;
}

.content_container {
  padding: $gutter_padding_xs;
  @media (min-width: $sm_breakpoint) {
    padding: $gutter_padding_md;
  }

  height: calc(100% - ($breadcrumbs_height + (2 * $breadcrumbs_margin)));
  overflow: hidden;
}

$input_wrapper_height: 16rem;

.input_wrapper {
  min-height: $input_wrapper_height;
}

.heading {
  font-size: 15px;
  color: $blue_grey_text_3;
}

.heading_icon {
  font-size: 20px;
}


.history_wrapper {
  height: 50%;
  position: relative;
  overflow: hidden;
}

.history {
  max-height: calc(100% - 1rem);
  padding: .5rem;
  background-color: $lightest_bg;
}

$controls_height: 3.5rem;
$controls_padding_bottom: 1rem;

.controls {
  height: $controls_height;
  padding-bottom: $controls_padding_bottom;
}

.table {
  height: calc(100% - #{$controls_height + $controls_padding_bottom});
  overflow: auto;

  @include always_show_y_scrollbar;
}