@use '../../../style_imports/colours' as *;

.dropdown_toggle {
  padding-top: 0;
  padding-bottom: 0;
  font-size: small;
  background-color: $lightest_bg;

  &:hover, &:active, &:focus {
    background-color: $lightest_bg;
  }
}
