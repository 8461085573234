@use '../../style_imports/layout' as *;

.block {
  position: relative;
}

.spinner_wrapper {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
}

.carousel {
  display: block;
  background-color: transparent;
  white-space: nowrap;

  font-size: 0;

  &__x {
    overflow-x: auto;
    overflow-y: hidden;
    @include always_show_x_scrollbar;
  }

  &__y {
    overflow-x: hidden;
    overflow-y: auto;
    @include always_show_y_scrollbar;
  }
}
.image {
  cursor: zoom-in;
  height: 128px;
}

