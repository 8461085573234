@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.table {
  width: 100%;

  th, td {
    padding: .5rem;
  }

  td {
    border: 1px solid $light_grey_border;
    vertical-align: top;
  }
}

.geo_table {
  width: 100%;

  @media (min-width: $sm_breakpoint) {
    width: 30%;
  }
}

.table_column_width_30 {
  width: 30%;
}

.table_column_width_40 {
  width: 40%;
}

.table_column_width_50 {
  width: 50%;
}