@use '../../../style_imports/layout' as *;
@use '../../../style_imports/colours' as *;

.list_controls {
  top: 0;
  background-color: $lightest_bg;
}

.save_icon {
  margin-left: 0.2rem;
  vertical-align: bottom;
}

.report_name_field {
  overflow: visible !important;
}

.report_save_field {
  text-overflow: unset !important;
}

.table {
  overflow-x: auto;
}