@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.subs_basket_container {
  border: 1px solid $light_grey_border;
  background-color: $lightest_bg;
}

.subs_basket_header_wrapper {
  background-color: $grey_bg_2;
  border-bottom: 1px solid $light_grey_border;
  color: $grey_text_1;
  font-size: 16px;
  font-weight: 700;
}

.settings_btn {
  padding-top: 0.2rem;
  color: $dark_text;
  font-size: 24px;
}

.controls_label {
  height: 1.5rem;
  font-size: 12px;
}

.subs_basket_prompt {
  color: $label_text;
}

.subs_basket_items_container {
  min-height: 12rem;
  padding: 0.5rem;
}

.subs_basket_success_text {
  color: $label_text;
  padding-top: 1rem;
}