@use '../../style_imports/colours' as *;

.title_bar {
  background-color: $grey_bg_2;
  padding: 0.6rem 1.5rem 0.2rem;
  border-bottom: 1px solid $blue_grey_border_1;
}

.title {
  font-weight: 700;
  color: $dark_text;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.back_button {
  font-size: 22px;
  margin-right: 0.8rem;
}