@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.block {
  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.__enabled {
  &::after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: all 0.2s ease 0s;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: $border_radius_small;
    transform-origin: center center;
    background-color: $blue_border;
  }

  &:hover::after {
    opacity: 0.15;
  }
}

.input {
  position: absolute;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}


.checkbox {
  display: flex;
  position: relative;
  border: 2px solid $light_grey_border;
  border-radius: 2px;
  width: 1rem;
  height: 1rem;
  background-color: $lightest_bg;
}

.__checked {
  display: flex;
  position: relative;
  border: 2px solid $input_active;
  border-radius: 2px;
  width: 1rem;
  height: 1rem;
  background-color: $input_active;
}

.__disabled {
  cursor: default;
  border: 2px solid $disabled_selector;
}

.__disabled_checked {
  cursor: default;
  background-color: $disabled_selector;
  border: 2px solid $disabled_selector;
}