html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: #212121;
  font: 400 15px / 1.475 Lato, NotoSansJP, NotoSansKR, NotoSansSC, serif;
}

h1 {
  font: bold 36px / 1.25 Lato, NotoSansJP, NotoSansKR, NotoSansSC, serif;
  margin: 0;
  padding: 0;
}

h1 sup {
  font-size: 14px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h4 {
  font-size: 21px;
  font-weight: 400;
  line-height: 27px;
}

h5 {
  font: 600 18px / 1.3 Lato, NotoSansJP, NotoSansKR, NotoSansSC, serif;
  margin: 0;
  padding: 0;
}

h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

hr {
  border-color: #ACBAC9; /*ps_dark_100*/
}

label {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: #455a64; /*ps_blue_grey_700*/
}

.cipher_init_error_container {
  margin: 4rem;
  text-align: center;
}

.cipher_init_error_link {
  color: #D60019; /*$ps_red_700*/
  text-decoration: underline;
  cursor: pointer;
}

b {
  font-weight: 700;
}

a {
  color: #006ebb; /*ps_light_blue_800*/
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: #039ae5; /*ps_light_blue_600*/
}
