@use '../../style_imports/colours' as *;

.button,
.button:hover,
.button:active {
  background-color: $lightest_bg !important;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.first_option {
  border-bottom: 1px dotted $light_grey_border;
}

.label_link {
  font-size: smaller;
}

.recent_block {
  font-size: small;
}