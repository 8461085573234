@use '../../style_imports/layout' as *;

.block {
  width: 100%;
  margin-bottom: $gutter_padding_sm;

  @media (min-width: $md_breakpoint) {
    width: 50%;

    &:nth-child(odd) {
      padding-right: 0.5rem;
    }

    &:nth-child(even) {
      padding-left: 0.5rem;
    }

  }

  @media (min-width: $lg_breakpoint) {
    width: 33.33333333%;

    &:nth-child(odd),
    &:nth-child(even) {
      padding-right: 0;
      padding-left: 0;
    }

    &:nth-child(3n+0) {
      padding-left: 0.5rem;
    }

    &:nth-child(3n+1) {
      padding-right: 0.5rem;
    }

    &:nth-child(3n+2) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

  }

}