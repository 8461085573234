@use '../../../style_imports/colours' as *;

.label {
  cursor: pointer;
  &:hover {
    .name {
      text-decoration: underline;
    }
  }
}

.details_wrapper {
  border-top: 1px solid $light_grey_border;
}