.link_input_wrapper {

}

.link_input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.copy_btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}