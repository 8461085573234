@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.wrapper {
  border-radius: $border_radius_small;
  background-color: rgba($lightest_bg, 0.7);

  padding: .25rem .25rem 0 .25rem;
  -webkit-box-shadow: .5rem .5rem .5rem -0.25rem rgba($grey_bg_1, 1);
  -moz-box-shadow: .5rem .5rem .5rem -0.25rem rgba($grey_bg_1, 1);
  box-shadow: .5rem .5rem .5rem -0.25rem rgba($grey_bg_1, 1);
}

.option {
  margin-left: .5rem;

  &:first-child {
    margin-left: 0;
  }
}

