@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.wrapper {
  background-color: $grey_bg_2;
}

.wrapper__top {
  border-top: 1px solid $light_grey_border;
}

.wrapper__summary_row {
  @media (min-width: $md_breakpoint) {
    height: $knn_input_summary_row_height;
    position: sticky;
    top: $header_height_xl;
    z-index: 1011;
  }
}

.wrapper__controls_row {
  @media (min-width: $md_breakpoint) {
    height: $knn_input_controls_row_height;
    position: sticky;
    top: calc($header_height_xl + $knn_input_summary_row_height);
    z-index: 1011;
  }
}

.summary {
  border-bottom: 1px solid $light_grey_border;
}

.text_item {
  background-color: $lightest_bg;
  &:hover {
    background-color: $lightest_bg !important;
  }
}

.text_unparsed_input {
  border: none !important;
}

.count_select_btn {
  background-color: $lightest_bg;

  &:hover, &:focus, &:active, &:active:focus {
    background-color: $lightest_bg;
  }
}

.positive_info {
  color: $knn_positive_label;
}

.negative_info {
  color: $knn_negative_label;
}

.bookmark_info {
  color: $knn_bookmark_label;
}

.save_btn {
  width: 7rem;
}

.checkbox {
  top: 2px;
}

.chevron {
  color: $blue_grey_text_4;
}

.add_text_link {
  &:hover {
    .add_text_link_label {
      text-decoration: underline;
    }
  }
}

$privacy_control_wrapper_width: 13.5rem;

.breadcrumbs_wrapper {
  width: calc(100% - $privacy_control_wrapper_width);
  overflow-x: hidden;
  height: 2rem;
}

.privacy_control {
  width: $privacy_control_wrapper_width;
}

.breadcrumb_icon {
  font-size: 20px;
}

.breadcrumb_link {
  font-size: 18px;
}

.search_name {
  font-size: 16px;
  font-weight: 700;
  color: $blue_grey_text_3;

  overflow-x: hidden;
  text-overflow: ellipsis;
}
