@use '../../style_imports/colours' as *;


.stepper {
  height: 40px;
  width: 156px;
  border: $light_grey_border 2px solid;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  &:hover {
    border-color: $dark_grey_border;
  }
  &:active {
    border-color: $bright_blue_border;
  }
}

.stepper__invalid {
  border-color: $system_error !important;
}

.stepper__disabled {
  background-color: $control_disabled;
  color: $disabled_txt;
  cursor: default;
  &:hover{
    border-color: $light_grey_border;
  }
}

.decrement {
  padding-left: 0.5rem;
}

.increment {
  padding-right: 0.5rem;
}

.button, .button:hover {
  color: $blue_text !important;
  width: 24px;
  font-size: 16px;
}

.button__disabled {
  color: $disabled_txt !important;
}