@use '../../style_imports/family_details_view_layout' as *;

.anchor {
  top: calc(($top_wrapper_height + $nav_wrapper_height) * -1);
  left: 0;
}

.menu_toggle {
  border: none;
  padding: 0;
}