@use '../style_imports/layout' as *;

.logo {
  color: transparent;
  background-repeat: no-repeat;

  height: $header_height_xl;
  margin-top: 0.75rem;

  &:hover {
    text-decoration: none;
    color: transparent;
  }
}

.logo_cipher {
  background-image: url('../static/images/classification-logo.png');
  background-repeat: no-repeat;
  background-size: 188px 40px;
  height: 40px;
  width: 188px;
}

.logo_techdiscovery {
  background-image: url('../static/images/techdiscovery-logo.png');
  background-repeat: no-repeat;
  background-size: 216px 40px;
  height: 40px;
  width: 216px;
}

.logo_mark_only {
  background-image: url('../static/images/LN-mark.png');
  background-repeat: no-repeat;
  background-size: 45px 40px;
  height: 40px;
  width: 45px;
  margin-top: 0;
}

.page_title {
  padding-left: calc(188px + 1rem);
}
