@use '../../style_imports/colours' as *;

.connector {
  background-color: $inactive;
  height: 2px;
  width: 50%;
}

.connector__complete {
  background-color: $link_text;
}

.connector__invisible {
  background-color: transparent !important;
}

.marker {
  color: $inactive;
}

.marker__active, .marker__complete {
  color: $link_text;
}

.label {
  font-size: 14px;
  font-weight: 700;
  color: $ps_dark_900;
}

.subtitle {
  font-size: 12px;
  color: $label_text;
}