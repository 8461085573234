@use '../../style_imports/colours' as *;
@use '../../style_imports/_layout' as *;

$heading_height: 3rem;
$heading_width: 15rem;

.display_marker {
  width: .25rem;
}

.display_marker__active {
  background-color: $navigation_marker;
}

.heading {
  width: $heading_width;


  height: $heading_height;
  color: $blue_grey_text_4;
  font-size: 16px;
  font-weight: 700;
}

.heading__selected {
  color: $dark_text;
}

.heading__group_displayed {
  width: 5rem;

  @media (min-width: $sm_breakpoint) {
    width: $heading_width;
  }
}

.heading__disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: $control_disabled_txt;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected_mark {
  color: $input_active;
}