@use '../../../style_imports/colours' as *;
@use '../../../style_imports/_layout' as *;

.button_container {
  color: $dark_text;
  cursor: pointer;
  font-size: 18px;
  border: 4px solid transparent;
  border-radius: $border_radius_small;
  background-color: transparent;
  text-align: center;
  display: flex;
  height: 100% !important;

  &:hover {
    background-color: $blue_grey_bg_1;
  }

  &__selected {
    cursor: default;
    pointer-events: none;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.button_container__has_label {
  &:hover {
    background-color: $classifier_label_button_hover;
  }
}

.button_container__disabled {
  cursor: default;
  pointer-events: none;
  &:hover {
    background-color: transparent;
  }
}

.label_icon {
  display: flex;
  margin: auto;
}

.label_icon__disabled {
  opacity: 0.15;
  cursor: default;
  pointer-events: none;
}

.label_icon__selected {
  cursor: default;
}

.label_as_positive {
  &:hover, &__selected {
    color: $classifier_label_positive;
  }
}

.label_as_negative {
  &:hover, &__selected {
    color: $classifier_label_negative;
  }
}

.label_as_test_positive {
  &:hover, &__selected {
    color: $classifier_label_test_positive;
  }
}

.label_as_test_negative {
  &:hover, &__selected {
    color: $classifier_label_test_negative;
  }
}

.label_as_ignore {
  &:hover, &__selected {
    color: $classifier_label_ignore;
  }
}

.remove_label_icon {
  color: $dark_text;
}

.left_separator {
  border-left: 1px solid $light_grey_border;
  min-width: 1px;
  height: 1.2rem;
}
