@use '../style_imports/_layout' as *;

.block {
  padding-left: $gutter_padding_xs;
  padding-right: $gutter_padding_xs;

  @media (min-width: $sm_breakpoint) {
    padding-left: $gutter_padding_md;
    padding-right: $gutter_padding_md;
  }
}

.block__with_scroll {
  padding-left: calc($gutter_padding_xs - $scrollbar_padding);
  padding-right: calc($gutter_padding_xs - $scrollbar_padding);

  @media (min-width: $sm_breakpoint) {
    padding-left: calc($gutter_padding_md - $scrollbar_padding);
    padding-right: calc($gutter_padding_md - $scrollbar_padding);
  }
}