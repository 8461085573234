@use '../../style_imports/colours' as *;

.title {
  height: auto;
}

.pagination_controls {
  font-size: x-small;
  text-transform: none;
  font-weight: initial;
}

.label__disabled {
  color: $control_disabled_txt;
  cursor: default;
}

.edit_link, .edit_link:visited {
  text-decoration: none;
  font-weight: normal;
  text-transform: none;
  color: $link_text;
  padding-top: 0.2rem;
  &:hover {
    color: $link_text_hover
  }
}

.edit_link__disabled {
  opacity: 0.3;
  cursor: pointer;
}