@use '../../../style_imports/colours' as *;
@use '../../../style_imports/_layout' as *;

.block {
  height: 1rem; // ensures that spinner does not make the div jump in height
  white-space: nowrap;
}

.error_saving_message {
  color: $system_error;
  font-weight: 700;
}
