@use '../../../style_imports/layout' as *;
@use '../../../style_imports/colours' as *;

$family_details_header_height: 3rem;

$list_container_margin: 0.5rem;

.list_container {
  margin: $list_container_margin 0;
  height: calc(100% - (2 * $list_container_margin));
  overflow: hidden;
}

.list {
  overflow-y: auto;
  @include always_show_y_scrollbar;
}

.family_details_header {
  position: sticky;
  top: 0 !important;
  background-color: $lightest_bg;
  height: $family_details_header_height !important;
}

.family_details_wrapper {
  height: calc(100% - $family_details_header_height);
}

.controls_row {
  // For setting sticky top on the PatentFamilyList controls (i.e. pins to below the headers)
  position: sticky;
  top: 0;
}

.family_details_controls_row {
  // For setting sticky top on the PatentFamilyList controls (i.e. pins to below the top of the screen)
  position: sticky;
  top: 0;
}

.table_header {
  // For setting sticky top on the Table header row (i.e. i.e. pins to below the controls row, see above)
  position: sticky;
  top: $patent_families_list_controls_row_height;
}