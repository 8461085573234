@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.dropdown_toggle {
  color: $darkest_text;
  text-align: center;
  font-size: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 3rem;
  border-radius: 4px;

  @media (min-width: $sm_breakpoint) {
    width: 6rem;
  }

  @media (min-width: $md_breakpoint) {
    width: 8rem;
  }

  @media (min-width: $lg_breakpoint) {
    width: 11.5rem;
  }

  &:focus {
    box-shadow: none;
  }
}

.dropdown_toggle__long {
  @media (min-width: $sm_breakpoint) {
    width: 7rem;
  }

  @media (min-width: $md_breakpoint) {
    width: 10rem;
  }

  @media (min-width: $lg_breakpoint) {
    width: 13rem;
  }
}

.dropdown_toggle__x_long {
  @media (min-width: $md_breakpoint) {
    width: 11rem;
  }

  @media (min-width: $lg_breakpoint) {
    width: 15rem;
  }
}

.dropdown_toggle:hover,
.dropdown_toggle:focus,
.dropdown_toggle__open,
.dropdown_toggle__open:hover {
  text-shadow: .5px 0 0 $grey_text_1;
  color: $grey_text_1;
  text-decoration: none;
}

.dropdown_toggle_text {
  overflow: hidden;
}

.dropdown_menu {
  background-color: $lightest_bg;
  color: $grey_text_1;
}

.dropdown_toggle__disabled,
.dropdown_toggle__disabled:disabled {
  background-color: $control_disabled;
  opacity: 0.55;
}

.dropdown_toggle__highlighted {
  border-radius: 4px;
  background-color: $filter_toggle_highlight;
}

.dropdown_menu {
  border-radius: 0;
  border: 1px solid $light_grey_border;
  font-size: .85rem;
  width: 21rem;
}

.dropdown_menu:focus {
  outline: none;
}