.filter_dropdown_container {
  max-height: 50vh;
}

.filter_dropdown_menu {
  z-index: 1030;
  min-width: 15rem !important;
  margin-right: 1rem;
  max-width: 20rem;
}

.filter_dropdown_menu:focus {
  outline: none;
}

.filter_dropdown_label {
  width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}