@use '../../../style_imports/colours' as *;
@use '../../../style_imports/layout' as *;

.page_wrapper {
  cursor: pointer;

  &:nth-child(odd) {
    background-color: $blue_grey_bg_1;
  }
}

.title_column {
  min-width: 12rem;
}

.chart_wrapper {
  width: 100%;
  @media (min-width: $sm_breakpoint) {
    width: 17rem;
  }
}

.chart_icon {
  fill: $benchmarking;
  & line {
    stroke: $benchmarking;
  }
}

.chart_name {
  color: $grey_text_1;
}

.description {
  color: $grey_text_2;
}
