.save_list_control {
  text-transform: none;
}

.save_list_dropdown {
  width: 15rem;
}

.newly_saved_list_message {
  font-size: small;
  white-space: nowrap;
  text-transform: none;
}