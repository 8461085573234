@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.header_container {
  background-color: $ps_dark_800;
  z-index: $header_z_index; /* to ensure child dropdowns render ok */
}

.header__admin {
  background-color: $card_header_embellish;
}

.header_navbar {
  height: $header_height_xl;
}

.feedback_link {
  cursor: pointer;
}

.logout_link {
  color: $lightest_text;

  &:hover {
    color: $lightest_text;
  }
}

.dropdown_menu {
  overflow-y: scroll;
  max-height: 75vh;
}

.title {
  color: $lightest_text;
  font-size: larger;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.created_at {
  font-size: small;
  color: $lightest_text;
}

.login_btn {
  color: $lightest_text;
}