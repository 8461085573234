@use '../../style_imports/colours' as *;

.section_header {
  color: $dark_text;
  border-bottom: 1px solid $light_grey_border;
}

.menu {
  color: $grey_text_1;
  font-size: inherit;
}

.name {
  word-break: break-word;
}