@use '../../style_imports/_layout' as *;
@use '../../style_imports/viewer_layout' as *;

.pie {
  height: $chart_default_height;

  width: 100%;
  @media (min-width: $md_breakpoint) {
    width: 400px;
  }
  @media (min-width: $lg_breakpoint) {
    width: 500px;
  }
}

.pie__thumbnail {
  height: $thumbnail_large_height;
}

.pie > div > div > div {
  // September 2019 - Pie's useResizeHandler can break (leaving slices of pie in weird places).
  // For now, resizeHandler is disabled.
  // The below workaround centres the pie.
  // It can be removed as soon as useResizeHandler is working again.
  // i.e. The ugly descendant selector is used to target the inner div (rendered by plotly).
  // i.e. The ugly !important is needed to override plotly.
  margin: 0 auto !important;
}