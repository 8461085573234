@use '../../style_imports/colours' as *;

.label {
  text-transform: uppercase;
  font-size: 14px;
}

.option {
  min-width: 1.8rem;
  height: 1.8rem;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  color: $dark_text;
  text-transform: uppercase;
  &:hover {
    background-color: $btn_hover_bg;
  }
}

.option__on, .option__on:hover {
  color: $lightest_text;
  background-color: $item_active;
}

.disabled {
  color: $control_disabled_txt;
  background-color: $control_disabled;
}