@use '../style_imports/layout' as *;
@use '../style_imports/colours' as *;

/*remove bootstrap's blue outline around input box */
.form-control {
  border-radius: $border_radius_small;
  border: $border_thickness solid $light_grey_border;
  height: $ps_input_height;
  margin: 1px; // spacing to allow for extra outline on focus

  &:focus {
    border-color: $bright_blue_border;
    outline: 1px solid rgba(79, 195, 247, .40);
    box-shadow: none;
  }

  &:hover {
    border-color: $dark_grey_border;
    outline: none;
  }

  &.is-invalid {
    background-image: none;
  }

  &::placeholder {
    color: $placeholder_text;
    font-size: 16px;
    font-weight: 400;
  }
}

textarea.form-control {
  border-width: 2px;
}

.btn {
  border-radius: $border_radius_small !important;

  &.active.focus, &.active:focus, &:active &.focus, &:active.focus, &:active:focus, &:focus {
    box-shadow: none;
  }
}

.dropdown-menu {
  outline: none !important;
  border-radius: $border_radius_small;
  border-color: $light_grey_border !important;
  padding: .3rem;
  margin-top: 2px;
  top: auto;
  width: min-content;
  max-width: 50rem;
}

.dropdown-divider {
  color: $blue_grey_text_3;
  margin: 2px 0;
  padding: 0;
}

.dropdown-item,
.dropdown-header {
  color: $blue_grey_text_3;
  padding: .5rem;
  overflow: hidden;
}

.dropdown-item {
  font-size: 15px;
  font-weight: 400;
  border-radius: $border_radius_small !important;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;

  &.active {
    background: none;
    color: $blue_grey_text_3;
    &::after {
      display: inline;
      content: '';
      width: 0.5rem;
      height: 1rem;
      margin-left: 0.5rem;
      border-bottom: 1px solid $dark_text;
      border-right: 1px solid $dark_text;
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }

  &:hover {
    color: $blue_grey_text_3;
    background-color: $light_blue_bg_1;
  }

  &:focus {
    /* remove bootstrap's blue outline around input box */
    color: $blue_grey_text_3;
    background: none;
    border-color: $dark_grey_border;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none !important;
    &:hover {
      background-color: $light_blue_bg_1;
    }
  }

  &.disabled {
    color: $disabled_txt;
  }

  &.disabled:hover {
    cursor: default;
  }
}

.modal-content {
  border-radius: 0;
}

.sticky-top {
  // need to explicitly add this for IE11 polyfill
  // (can be removed once we stop supporting IE11)
  position: sticky;
  z-index: 1020;
  top: 0;
}

.dropdown-menu {
  z-index: 1040; // make sure shows above sticky-top
}

.popover {
  max-width: 500px;
}

.input-group {
  & > .form-control {
    border-radius: $border_radius_small !important;
  }

  & > .input-group-append > .btn, .input-group-text {
    border-radius: $border_radius_small !important;
  }

  & > .input-group-prepend > .btn, .input-group-text {
    border-radius: $border_radius_small !important;
  }
}

.form-control.is-invalid {
  background-image: none;
}

/*
  Overrides from Tooltip.js
*/
.tooltip-inner {
  padding: 0;
  max-width: 20rem;
}

/*
  Overrides from InfoToolip.js
*/
.tooltip-inner {
  border: 1px solid $dark_grey_border;
  background-color: $lightest_bg;
  color: $blue_grey_text_1;
}

.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: $dark_grey_border;
    }
  }
}

.tooltip {
  // Workaround to prevent flickering of tooltips in table elements.
  // It seems that on the border between two table elements, the tooltip itself receives events
  // (mouseover, mouseout, mouseenter, mouseleave etc...),
  // which can cause an infininite loop of opening and closing.
  pointer-events: none;
}