.tag_dropdown_menu {
  padding: .1rem .25rem;
  z-index: 1030;
  font-size: 0.875rem !important;
  min-width: 15rem;
}

.tag_dropdown_menu:focus {
  outline: none;
}

.dropdown_container {
  z-index: 100;
}

.tags_container {
  height: 30vh;
}

.tooltip {
  font-size: smaller;
  width: 9rem;
}