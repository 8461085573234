@use '../../style_imports/layout' as *;
@use '../../style_imports/viewer_layout' as *;

$two_column_breakpoint:   42rem;
$three_column_breakpoint: 66rem;
$four_column_breakpoint:  87rem;

$grid_gap: 2rem;

.wrapper {
  max-width: $sm_breakpoint;

  @media (min-width: $two_column_breakpoint) {
    width: calc(2 * #{$dataset_group_dim} + $grid_gap);
    max-width: calc(2 * #{$dataset_group_dim} + $grid_gap);
  }

  @media (min-width: $three_column_breakpoint) {
    width: calc(3 * #{$dataset_group_dim} + 2 * $grid_gap);
    max-width: calc(3 * #{$dataset_group_dim} + 2 * $grid_gap);
  }

  @media (min-width: $four_column_breakpoint) {
    width: calc(4 * #{$dataset_group_dim} + 3 * $grid_gap);
    max-width: calc(4 * #{$dataset_group_dim} + 3 * $grid_gap);
  }
}

.block {
  display: grid;
  grid-gap: $grid_gap;
  grid-template-columns: $dataset_group_dim;
  justify-content: center;

  @media (min-width: $two_column_breakpoint)  {
    grid-template-columns: $dataset_group_dim max-content;
    justify-content: space-between;
  }

  @media (min-width: $three_column_breakpoint)  {
    grid-template-columns: $dataset_group_dim max-content $dataset_group_dim;
    justify-content: space-between;
  }

  @media (min-width: $four_column_breakpoint)  {
    grid-template-columns: $dataset_group_dim max-content max-content $dataset_group_dim;
    justify-content: space-between;
  }
}
