@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

$list_width_lg: 10rem;
$list_width_xl: 15rem;
$list_width_xxl: 20rem;

.list_container {
  display: none;
  width: 0;

  @media (min-width: $lg_breakpoint) {
    display: block;
  }

  @media (min-width: $lg_breakpoint) {
    width: $list_width_lg;
  }

  @media (min-width: $xl_breakpoint) {
    width: $list_width_xl;
  }

  @media (min-width: $xxl_breakpoint) {
    width: $list_width_xxl;
  }

  height: 100%;
  overflow-y: scroll;
  @include always_show_y_scrollbar;
}

.details_container {
  width: 100%;

  @media (min-width: $lg_breakpoint) {
    padding: 0 .5rem;
  }

  @media (min-width: $lg_breakpoint) {
    width: calc(100% - #{$list_width_lg});
  }

  @media (min-width: $xl_breakpoint) {
    width: calc(100% - #{$list_width_xl});
  }

  @media (min-width: $xxl_breakpoint) {
    width: calc(100% - #{$list_width_xxl});
  }

  height: 100%;
  overflow-y: scroll;
  @include always_show_y_scrollbar;
}
