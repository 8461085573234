@use '../../style_imports/_colours' as *;

.header {
  border-bottom: 0.1rem solid $light_blue_border;
  height: 3rem;
}

.level_items {
  color: $dataset_header; // for consistency with preview grid
  overflow: hidden;
  white-space: nowrap;
}

.marker {
  width: .25rem;
}

.label__group {
  font-weight: 700;
}

.label__limit_width {
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb_link {
  cursor: pointer;
  color: $dataset_header; // for consistency with preview grid
  text-decoration: none;
}
.breadcrumb_link:hover {
  color: $link_text_hover;
}
.breadcrumb_link__selected,
.breadcrumb_link__selected:hover {
  color: $dataset_header;
  cursor: default;
  text-decoration: none;
}

.filters {
  width: 3rem;
  text-align: center;
}

.description {
  font-size: small;
  white-space: normal;
}

.back_btn {
  font-size: 22px;
  width: 3.2rem !important;

  &:hover, &:active, &:focus {
    font-size: 22px;
  }
}

.info_popup {
  background-color: $lightest_bg;
  border: 1px solid $light_grey_border;
}