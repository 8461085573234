@import '../../style_imports/colours';

.clear_icon {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: $icon_btn_bg;

  &:hover {
    background-color: $icon_btn_bg_active;
  }
}