@use '../../style_imports/layout' as *;

.tabular {
  margin-bottom: 1rem;
}

.tabular__thumbnail {
  overflow: hidden;
  font-size: x-small;
}
