@use '../../../style_imports/layout' as *;

.clickable_icon {
  padding-left: 0.1rem;
  padding-right: 0.2rem;
}

.edit_text_input {
  max-height: 1.4rem;
  font-size: 0.875rem;
  padding: 0 0.1rem 0 0.1rem;
  margin-right: 0.2rem;
  background-image: none !important;
}

.text_input {
  width: 90%;
  text-align: left;
}

.edit_icon {
  width: 9%;
  text-align: right;
}