
@use '../../style_imports/_layout' as *;

.classifiers_group_wrapper {
  min-width: 20rem;
}

.classifiers_group_display {
  height: initial;
}

.classifiers_group__paginatable {
  width: 25rem;
}

.search_input {
  border-width: 1px !important;
}