@use '../../style_imports/colours' as *;

.org_top_level_wrapper {
  border-bottom: 1px solid $light_grey_border;

  &:last-child {
    border-bottom: none;
  }
}

.org_next_level_wrapper {
  border-bottom: 1px solid $blue_grey_border_3;

  &:last-child {
    border-bottom: none;
  }
}

.expand_control_wrapper {
  width: 1.5rem;
}

.sorting_wrapper {
  border-top: 2px solid $blue_border;
}

.grouping_controls {
  font-size: 14px;
}

.org_name_block {
  min-height: 1.7rem;
}

.show_more_icon {
  display: block;
  color: $grey_text_1;
  .org_wrapper:hover & {
    display: none;
  }
}

.options {
  display: none;
  position: absolute;
  top: .25rem;
  right: .1rem;

  .org_wrapper:hover & {
    display: flex;
  }
}
