@use '../../style_imports/colours' as *;

.section {
  border-right: 1px solid $light_grey_border;
}

.tag {
  font-weight: normal;
}

.tag__disabled {
  color: $control_disabled_txt;
  border: 1px solid $control_disabled;
  background-color: $control_disabled;
}

.tag__selected {
  // TODO?
  //background-color: $input_active;
  //color: $white_text;
}

.cross_icon {
  width: .5rem;
  height: .5rem;
}
