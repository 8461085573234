@use '../../style_imports/colours' as *;
@use '../../style_imports/viewer_layout' as *;

$margin: .5rem;

.item_wrapper {
  border: 1px solid $light_blue_border;
  margin-right: $margin;

  width: 100%;
}

.item_wrapper__with_ribbon {
  width: calc(100% - #{$dataset_group_ribbon_width + $margin});
}