@use '../../style_imports/colours' as *;

.org_name {
  color: $grey_text_1;
  font-weight: 700;
  font-size: 15px;
}

.org_name__agglom {
  font-style: italic;
}

.icon_wrapper {
  color: $link_text;
}