@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.date_input {
  width: 7rem;
  font-size: 14px;
  border: $border_thickness solid $light_grey_border;
  border-radius: $border_radius_small;
  text-align: left;
  padding: 0.25rem 0.35rem !important;
  color: $dark_text;
  cursor: pointer;

  &:hover {
    border-color: $dark_grey_border;
  }

  &:focus {
    border-color: $bright_blue_border;
    outline: 1px solid rgba(79, 195, 247, .40);
  }

  &__disabled {
    color: $control_disabled_txt;
    cursor: default;
  }
}

.date_input_with_error {
  border-color: $system_error;
  outline: 1px solid rgba(214, 0, 25, .40);
}

.calendar_dropdown_button {
  font-weight: bold;
  font-size: 0.9rem;
  border: none;
  min-width: 6.4rem;
}

.calendar_year_dropdown_button {
  min-width: 4rem;
}

.calendar_dropdown {
  min-width: 4rem !important;
  // dropshadow is present in some designs but not others; leave it out for consistency for now
  // border: none;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
}