@use '../../style_imports/colours' as *;

.navigation_wrapper {
  width: 100%;
  border-bottom: 1px solid $light_grey_border;
}

.nav_tab {
  font-size: 16px;
  font-weight: 700;
  color: $blue_grey_text_4;
  cursor: pointer;

  &__active {
    cursor: default;
    color: $dark_text
  }

  &__disabled {
    cursor: not-allowed;
    color: $disabled_txt;
  }
}


.__active_marker {
  width: 100%;
  height: 4px;
  background-color: $navigation_marker;
  position: absolute;
  bottom: 0;
}

.option_marker {
  width: .25rem;
  margin-right: 0.5rem;
  &__active {
    background-color: $navigation_marker;
  }
}
