@use '../../../style_imports/bespoke_viewer' as *;

.block {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.content {
  height: calc(100vh - #{$bespoke_header_height + $bespoke_footer_height});
  overflow-y: scroll;
}
