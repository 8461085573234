@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.edit_control_on_hover {
  display: none;

  .text__static:hover & {
    display: block
  }
}

.text__static {
  border: 1px solid $light_grey_border;
  border-radius: $border_radius_small;
  width: fit-content;
}