@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;
@use '../../style_imports/viewer_layout' as *;

.wrapper {
  background-color: $grey_bg_2;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-bottom: 1px solid $blue_grey_border_1;
}

.block {
  height: $report_controls_bar_height;
}

.title {
  font-weight: 700;
  color: $dark_text;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.details_text {
  font-size: 15px;
  color: $dark_text;
}

.details {
  border-right: 1px solid $blue_grey_border_1;
  padding: 0 4px;
  min-width: 15%;

  &:last-child {
    border-right: none;
    padding-right: 0;
  }
}

.report_icon {
  font-size: x-large;
  padding: 0 .3rem;
  color: $lightest_text;
  background-color: $item_active;
  border-radius: 4px;
  cursor: default;
  &:hover {
    color: $lightest_text;
    text-decoration: none;
  }
}

.report_icon_active, .report_icon_active:hover {
  cursor: pointer;
  color: $dark_text;
  background-color: transparent;
  &:hover {
    background-color: $btn_hover_bg;
    text-decoration: none;
  }
}

.filters {
  height: 1.9rem;
  border-left: none;

  @media (min-width: $lg_breakpoint) {
    border-left: 1px solid $blue_grey_border_1;
  }
}

.filters_icon {
  font-size: large;
  color: $lightest_text;

  &:hover {
    color: $lightest_text;
  }
}

.link {
  white-space: nowrap;
}

.incomplete_icon {
  color: $attention;
}
