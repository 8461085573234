@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.text_area {
  resize: none;
  height: 100%;
  &:hover {
    border-color: $dark_grey_border;
  }

  &:active {
    border-color: $bright_blue_border;
    outline: 1px solid rgba(79, 195, 247, .40);
  }

  overflow: auto;
  @include always_show_scrollbar;
}