@use '../../style_imports/colours' as *;

.monitor_card {
  border: solid 1px $dark_grey_border;
  border-radius: 4px;
  margin-top: 0.5rem;
  width: 70rem;
  margin-left: auto;
  margin-right: auto;
}

.child_content {
  max-height: 75vh;
  overflow: auto;
  margin-top: 0.5rem;
  border-top: 0.1rem dotted $light_blue_text;
  padding-top: 0.5rem;
}

.hint_text {
  font-size: small;
  text-transform: none;
  font-weight: normal;
  color: $blue_text;
}

.source_desc {
  text-align-last: end;
  min-width: 38%;
}

.download_button {
  min-width: 8rem;
}