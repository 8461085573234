@use '../../style_imports/colours' as *;

.change_password_link {
  border: none;
  background: none;
  padding: 0;
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}
