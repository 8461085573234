@import '../style_imports/colours';

$category_menu_width: 15rem;

.category_btn {
  width: $category_menu_width;
}

.category_menu {
  width: $category_menu_width;
}

.footer {
  border-top: 1px solid $light_grey_border;
}