@use '../../style_imports/colours' as *;

.header_wrapper {
  overflow: visible;
  height: 1.5rem;
  flex-grow: 2;
}

.header_wrapper__no_sort {
  cursor: default;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.label__sortable {
  cursor: pointer;
}

.sort_icon {
  margin-left: .75rem;
}
