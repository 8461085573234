@use '../../style_imports/colours' as *;

.page_overlay {
  position: absolute;
  z-index: 10000;
  background-color: rgba($dark_bg_3, .5);
}

.spinner_wrapper {
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4rem;
  margin-left: -4rem;
}