@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.input_block {
  display: inline-block;
  min-height: 2rem;
  min-width: 1rem;
  margin-right: auto;
  color: $grey_text_1;
  font-size: medium;
}

.date_input {
  color: $grey_text_1 !important;
  font-size: medium !important;
  border-radius: $border_radius_small;
  border: $light_grey_border 2px solid;
  height: 40px;
}