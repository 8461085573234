@use '../style_imports/colours' as *;

.block {
  margin-left: 0.65rem;
  margin-right: 0.65rem;
  top: 0.25rem;
  width: 140px;
  height: 22px;
  cursor: pointer;
}

.slider_handle {
  color: transparent;
  background-color: $item_active;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  outline: none;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.2s ease 0s;
    transform-origin: center center;
    background-color: $item_active;
    opacity: 0;
  }

  &:hover::after {
    opacity: 0.15;
  }

  &:active::after {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.slider_handle_single {
  background-color: $lightest_text;
  margin-top: 0.1rem;
  width: 0.7rem;
  height: 0.7rem;
  outline: $item_active solid 0.2rem;
}

.slider_label {
  color: $blue_grey_text_1;
}

.slider_track {
  background-color: $blue_grey_border_3;
  height: .2rem;
  border-radius: 2px;
  top: 0.33rem;
}

.slider_track_active {
  background-color: $item_active;
  border-radius: 0;
}
