.menu {
  max-height: 50vh;
  overflow-x: auto;
}

.block__overlap {
  width: 9rem;
  text-align: left;
}

.flex_form_group {
  display: flex;
  align-items: center;
}
.number_input {
  width: 3.5rem;
}