@use '../../../style_imports/colours' as *;

.block {
  background-color: $grey_bg_2;
}

.status {
  color: $dark_text;
  width: 1.4rem;
}

.status_filter_menu {
  width: 10rem;
}