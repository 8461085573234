@use '../../../style_imports/layout' as *;

.description_block {
  overflow: hidden;
  max-height: 7rem;
  @media (min-width: $sm_breakpoint) {
    height: 5rem;
    max-height: 5rem;
  }
}

.description_edit_btn {
  display: none;
}

.description_block__view {
  max-height: 7rem;
  overflow-y: scroll;
  @media (min-width: $sm_breakpoint) {
    height: 5rem;
    max-height: 5rem;
  }

  &:hover {
    .description_edit_btn {
      display: inline-block;
    }
  }
}