.menu {
  min-width: 13rem !important;
  padding-bottom: 0.5rem;
}

.button {
  padding: .125rem .5rem;
}

.button_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 5.5rem;
  text-align: left;
}

.row_container {
  cursor: default !important; // overide reactstrap default
  padding-top: 0;
  padding-bottom: 0;
}

.slider {
  width: 5rem;
}