@use '../../style_imports/colours' as *;

.modal_body {
  min-height: 10rem;
}

.rows_container {
  max-height: 60vh;
  overflow-y: scroll;
}

.role_container {
  pointer-events: none;
  &:hover {
    background-color: $list_item_hover;
  }
}

.action_link {
  pointer-events: auto;
  cursor: pointer;
}

.action_link__disabled {
  cursor: not-allowed;
  opacity: 0.2;
  font-style: italic;
}
