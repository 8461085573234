.no_shadow:hover {
  background-color: transparent !important;
}

.button {
  border: 2px solid;
  padding-top: 3px !important;     // workaround to prevent buttons from changing height of header
  padding-bottom: 3px !important;
  font-size: 16px;
}

.menu {
  min-width: 20rem;
}
