@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.operator_button {
  font-size: 18px;

  &:hover, &:active, &:focus {
    font-size: 18px;
  }
}

.presets_wrapper {
  background-color: $grey_bg_2;
  height: auto;
  @media (min-width: $md_breakpoint) {
    height: 3rem;
  }
}

.operator_buttons_wrapper {
  height: 3rem;
}

.report_basket_container {
  border: 1px solid $light_grey_border;
  background-color: $lightest_bg;
}

.report_basket_header_wrapper {
  background-color: $grey_bg_2;
  border-bottom: 1px solid $light_grey_border;
  color: $grey_text_1;
  font-size: 16px;
  font-weight: 700;
}

.report_basket_items_container {
  min-height: 12rem;
  padding: 0.5rem;
}

.report_basket_operand_column {
  width: 5rem;
}

.history_wrapper {
  overflow: auto;

  @include always_show_y_scrollbar;
}
