@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.card {
  background-color: $card_body;
  border-radius: $border_radius;
}

.card_heading_wrapper {
  color: $lightest_text;
  background-color: $card_header;
  border-top-left-radius: $border_radius;
  border-top-right-radius: $border_radius;

  .card_heading:before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
    height: 24px;
    width: 4px;
    background-color: $card_header_embellish;
  }
}

.__clickable {
  cursor: pointer;
  transition: all 0.375s ease-in-out 0s;
  &:hover {
    transform: translateY(-.5rem);

    .card {
      background-color: $card_body_hover;
    }

    .card_heading_wrapper {
      background-color: $card_body_hover;
    }
  }
}
