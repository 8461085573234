@use '../../style_imports/colours' as *;

.families_table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;

  & tr th {
    border-bottom: 2px solid $blue_border;
  }

  & tr td {
    border-bottom: 1px solid $light_grey_border;
  }

  & tbody tr:hover {
    background-color: $light_blue_bg_1;
  }

  & tbody {
    line-height: 1.8rem;
  }
}

.__selected {
  background-color: $list_item_selected;
  &:hover {
    background-color: $list_item_selected_hover !important;
  }
}

.table_header {
  height: 3.5rem;
}

.column_header {
  height: 2rem;
  padding: 0 .5rem;
}

.column_header_auto_height {
  height: auto;
}

.input_status_column_header {
  width: 1.5rem;
  padding: 0;
}

.cell {
  padding: 0 .5rem;
}

.cell__text {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
