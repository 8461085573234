@use '../../style_imports/layout' as *;

.legend {
  width: 100%;
  white-space: normal;
  flex-wrap: wrap;
  height: 1.3rem;
}

.legend_color {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}

.legend_label {
  font-size: small;
  white-space: nowrap;
  display: inline-block;
  width: 6rem;
  overflow: hidden;
  margin-right: 0.5rem;
  text-overflow:ellipsis;
}

.mini_label {
  font-size: x-small;
  margin-right: 0.2rem;
  margin-top: 0.1rem;
  width: 4rem;
}