@use '../../style_imports/colours' as *;

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: $icon_btn_bg;
  color: $label_text;

  &:hover {
    background-color: $icon_btn_bg_active;
  }
}

.close_btn__small {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}