@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.options_menu {
  overflow-y: auto;
  max-height: 75vh;
  border: 0;
  background-color: $menu_bg;
  padding: .25rem;
  border-radius: 0;
  margin: 0;
}

.menu_item {
  color: $lightest_text;
  font-size: 15px;
  &:hover {
    color: $lightest_text;
    background-color: $menu_hover;
    border-radius: $border_radius_small;
    text-decoration: none;
    &:focus {
      background-color: $menu_hover;
    }
  }
}

.report_options_toggle {
  color: $lightest_text !important;

  &:hover {
    color: $link_text_hover !important;
  }
}

.report_options_toggle__active {
  background: $menu_bg;
  color: $link_text_hover !important;
}

.menu_item__header {
  color: $link_text_hover
}

.menu_item__divider {
  border-color: $link_text;
  padding-bottom: 0.3rem;
}

.action__highlighted {
  color: $lightest_text !important;
}

.report_option_header {
  color: $light_blue_text;
}

.cookies_btn {
  border: none !important;

  color: $lightest_text !important;
  font-size: 15px !important;
  padding: .5rem !important;
  line-height: inherit !important;

  &:hover {
    color: $lightest_text !important;
    background-color: $menu_hover !important;
    border-radius: $border_radius_small !important;
    text-decoration: none;
    &:focus {
      background-color: $menu_hover !important;
    }
  }
}
