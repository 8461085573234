@use '../../../style_imports/colours' as *;
@use '../../../style_imports/layout' as *;

$group_width: 15rem;
$double_group_width: calc((2 * #{$group_width}) + .5rem);

.menu {
  min-width: $group_width;

  right: auto;
  left: 0;
}

.menu_wrapper {
  background-color: $lightest_bg;
  border: 1px solid $light_grey_border;
  border-radius: $border_radius_small;

  @media (min-width: $sm_breakpoint) {
    max-width: $double_group_width !important;
    width: $double_group_width;
  }

  @media (max-width: 59rem) and (min-width: 43rem) {
    right: 0;
    left: auto;
  }
}

.menu__multi_columns {
  width: 100%;
}

.list__multi_columns {
  overflow-x: auto;

  @include always_show_x_scrollbar;
}

.button_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: left;

  font-size: 16px;
  line-height: 24px;
  padding: 0.125rem 0.5rem;
  border-radius: $border_radius_small;
  border: $border_thickness solid $light_grey_border;
  color: $dark_text;

  &:hover {
    border-color: $dark_grey_border;
  }

  &:active {
    border-color: $bright_blue_border;
    outline: $border_thickness solid rgba(79, 195, 247, .40);
  }
}

.slider {
  width: 5rem;
}

.select_option {
  font-size: 0.875rem;
}

.group_block {
  width: $group_width;
}
