.list_action_icon {
  display: none;
  margin-left: 0.25rem;

  &:hover {
    text-decoration: none !important;
  }
}

.list_name_spacer {
  width: 1.3rem;
}

.list_add_link:hover {
  .list_action_icon {
    display: inline;
  }
}