@use '../../style_imports/layout' as *;

.title {
  white-space: nowrap;
  display: none;

  @media (min-width: $sm_breakpoint) {
    display: block;
  }
}

.title__short {
  display: block;
  font-size: smaller;
  white-space: nowrap;
  @media (min-width: $sm_breakpoint) {
    display: none;
  }
}
