@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.btn {
  color: $knn_neutral_label;
  cursor: pointer;
  border: $border_thickness solid transparent;
  border-radius: $border_radius_small;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;

  &:hover {
    background-color: $blue_grey_bg_1;
  }
}

.btn_positive {
  &:hover {
    color: $knn_positive_label;
  }
}

.btn_positive__active {
  color: $knn_positive_label;
}

.btn_negative {
  &:hover {
    color: $knn_negative_label;
  }
}

.btn_negative__active {
  color: $knn_negative_label;
}

.btn_bookmark {
  &:hover {
    color: $knn_bookmark_label;
  }
}

.btn_bookmark__active {
  color: $knn_bookmark_label;
}

