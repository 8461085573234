@use '../../style_imports/layout' as *;
@use '../../style_imports/viewer_layout' as *;
@use '../../style_imports/colours' as *;

.block {
  overflow-y: auto;
  @include always_show_y_scrollbar;
}

.controls_row {
  // For setting sticky top on the PatentFamilyList controls (i.e. pins to below the top of the screen)
  position: sticky;
  top: 0;
  z-index: 1010;
}

.table_header {
  // For setting sticky top on the Table header row (i.e. i.e. pins to below the controls row, see above)
  position: sticky;
  top: $patents_container_header_lg_height;
}

$family_details_header_height: 3rem;

.family_details_header {
  position: sticky;
  top: 0;
  z-index: 1010;
  background-color: $lightest_bg;
  height: $family_details_header_height;
}

.family_details_controls_row {
  // For setting sticky top on the PatentFamilyList controls (i.e. pins to below the top of the screen)
  top: 0;
  position: sticky;
}

.family_details_wrapper {
  height: calc(100% - $family_details_header_height);
}

.table_header {
  // For setting sticky top on the Table header row (i.e. i.e. pins to below the controls row, see above)
  position: sticky;
  top: $patents_container_header_lg_height;
}