@use '../../../style_imports/colours' as *;

.block {
  overflow: hidden;
}

.title_container {
  border-bottom: 1px solid $light_grey_border;
  color: $dark_text;
}

.title__category {
  text-transform: uppercase;
}

.chart_controls_block {
  height: 2rem;
}

.chart_container {
  overflow: scroll;
}

.options_menu_toggle {
  color: $attention;
}