@use '../../style_imports/colours' as *;

.panel {
  overflow: hidden;
}

.results_wrapper {
  height: 94%;
  overflow: scroll;
}

.basket_wrapper {
  overflow: hidden;
}

.basket_link {
  background-color: $lightest_bg;
  position: absolute;
  top: 0;
  right: 0;
}

.org_options_toggle {
  background-color: $dark_bg_1;
  border-color: $dark_bg_1;
  color: $lightest_text;
  font-size: smaller;
  &:hover, &:focus, &:active, &:active:focus {
    background-color: $dark_bg_1 !important;
    border-color: $dark_bg_1 !important;
  }
}