@use '../../../style_imports/colours' as *;
@use '../../../style_imports/layout' as *;

.menu_toggle {
  max-width: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.125rem 0.5rem;
  border-radius: $border_radius_small;
  border: $border_thickness solid $light_grey_border;
  color: $dark_text;
}

.menu {
  background-color: $lightest_bg;
  border: 1px solid $light_grey_border;
  border-radius: $border_radius_small;
}

.filter_menu {
  min-width: 4rem !important;
  width: auto;
}
