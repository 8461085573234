@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.org_match_dropdown_toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: $grey_bg_2;
}

.org_match_dropdown {
  width: 98%;
  // make sure popovers can pop over the top
  z-index: calc(#{$header_z_index} + 1);
}

.chevron_icon {
  font-size: large;
  color: $link_text;
}
