@use '../../style_imports/colours' as *;

.collapsed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.expanded {
  max-width: none !important;
  overflow: auto !important;
  white-space: normal !important;
}


.user_icon {
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}