$sm_breakpoint: 576px;
$md_breakpoint: 768px;
$lg_breakpoint: 992px;
$xl_breakpoint: 1200px;
$xxl_breakpoint: 1500px;

$scrollbar_padding: .5rem;

$gutter_padding_xs: 0.3125rem;
$gutter_padding_sm: 1rem;
$gutter_padding_md: 1.5rem;

$header_height_xl: 5rem; //80px
$header_height_sm: 50px; //TODO - apply for smaller screens

$tile_header_height: 3.5rem;

$patent_families_list_controls_row_height: 3rem;
$patents_container_header_height: 5rem;
$patents_container_header_lg_height: 2.5rem;

$classifiers_editor_header_height: 2.75rem;
$classifiers_editor_sidebar_width: 13rem;

$classifiers_editor_page_top: calc($header_height_xl + $classifiers_editor_header_height);

$classifiers_editor_card_min_width: 27rem;
$classifiers_editor_card_height: 18.625rem;
$classifiers_editor_card_gutter: 1rem;

$knn_input_summary_row_height: 3.3rem;
$knn_input_controls_row_height: 3.3rem;

$header_z_index: 10000;

$border_radius: 8px;
$border_radius_small: 4px;
$border_thickness: 2px;
$btn_border_thickness: 2px;

$modal_header_height: 32px;
$modal_content_padding: 2rem;

// values from PS style guidelines
$ps_input_height: 40px;
$font_weight_demi_bold: 700;

@mixin always_show_scrollbar {
  /* Force scrollbars on OSX Chrome/Safari - see http://simurai.com/blog/2011/07/26/webkit-scrollbar. */
  /* Unfortunately there is no way to force this for Firefox. */

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

@mixin always_show_light_scrollbar {

  @include always_show_y_scrollbar;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(140, 158, 177, .9); //ps_dark_200
    -webkit-box-shadow: 0 0 1px rgba(255,255,255, .3);
  }
}

@mixin always_show_y_scrollbar {
  @include always_show_scrollbar;

  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }
}

@mixin always_show_x_scrollbar {
  @include always_show_scrollbar;

  &::-webkit-scrollbar {
    width: 0;
    height: 4px;
  }
}

@mixin single_line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}