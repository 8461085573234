@use '../../style_imports/layout' as *;

.tabular {
  @media (min-width: $xl_breakpoint) {
    width: 80%;
  }
  @media (min-width: $xxl_breakpoint) {
    width: $xl_breakpoint;
  }

  margin: 0 auto;
}

.tabular__thumbnail {
  overflow: hidden;
  font-size: x-small;
}
