@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.tag_group_label {
  color: $grey_text_2;
  text-transform: uppercase;
  font-size: small;
}

.tag {
  font-weight: 600;

  border: 1px solid $grey_bg_1;
  background-color: $grey_bg_1;
  border-radius: $border_radius_small;
  cursor: pointer;
}

.tag__selected {
  border-color: $org_tag;
  background-color: $org_tag;

  color: $lightest_text;
}

