@use '../../../style_imports/colours' as *;
@use '../../../style_imports/layout' as *;

.controls_modal {
  width: calc(100% - (2 * 1.5rem));
  height: calc(100vh - (2 * 1.5rem));
}

.modal_body {
  overflow: hidden;
}

.options_wrapper {
  border-right: 1px solid $light_grey_border;
  width: 8rem;

  overflow-y: scroll;
  @include always_show_y_scrollbar;
}

.filter_wrapper {
  overflow: scroll;

  @include always_show_y_scrollbar;
}

.slider_label {
  color: $blue_grey_text_1;
}