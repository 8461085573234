@use '../../style_imports/layout' as *;

.controls_row {
  // For setting sticky top on the PatentFamilyGlobalSearch controls (i.e. pins to below the header)
  position: sticky;
  top: 0;

}

.table_header {
  // For setting sticky top on the Table header row (i.e. i.e. pins to below the controls row, see above)
  position: sticky;
  top: $patent_families_list_controls_row_height;
}