@use '../style_imports/colours' as *;

.wrapper {
  width: 60rem;
}

.icon_info {
  width: 30rem;
  border-bottom: 1px dotted $light_grey_border;
}

.icon {
  color: black;
  width: 2rem;

  & line {
    stroke: black;
  }
}
.icon__svg {
  fill: black;
  color: black;
  width: 1rem;
  height: 1rem;
}

.component_name {
  width: 12rem;
}

.library {
  width: 3rem
}