@use '../../style_imports/layout' as *;

$tile_header_height: $tile_header_height;

.panel {
  height: -webkit-calc(100% - #{$tile_header_height});
  height: -moz-calc(100% - #{$tile_header_height});
  height: calc(100% - #{$tile_header_height});

  min-height: 6rem;

  word-break: break-word;
}