@use '../../../style_imports/layout' as *;
@use '../../../style_imports/colours' as *;

.search_input {
  height: 2rem;
}

.dropdown_toggle {
  height: 1.3rem;
  color: $lightest_bg;
  background-color: $benchmarking;
  text-align: center;
  font-size: small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 3rem;

  @media (min-width: $sm_breakpoint) {
    width: 6rem;
  }

  @media (min-width: $md_breakpoint) {
    width: 9rem;
  }

  @media (min-width: $lg_breakpoint) {
    width: 12rem;
  }

  &:focus {
    box-shadow: none;
  }
}

.dropdown_toggle:hover,
.dropdown_toggle:focus,
.dropdown_toggle__open,
.dropdown_toggle__open:hover {
  text-shadow: .2px 0 0 $lightest_text;
  text-decoration: none;
}

.dropdown_menu {
  border-radius: 0;
  border: 1px solid $light_grey_border;
  font-size: .85rem;
  width: 16rem;
}

.dropdown_menu:focus {
  outline: none;
}

.dropdown_item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover, &:focus {
    background-color: rgba($benchmarking, .1);
  }
}