@use '../style_imports/layout' as *;
@use '../style_imports/colours' as *;

.block {
  margin: $gutter_padding_xs;

  @media (min-width: $sm_breakpoint) {
    margin: 1rem $gutter_padding_md;
  }

  @media (min-width: $xxl_breakpoint) {
    margin: 1rem auto;
    width: $xl_breakpoint;
    max-width: $xl_breakpoint;
  }
}

.lite_user_message {
  padding: 12px 8px;
  border-radius: 4px;
  background-color: $card_body;
  color: $light_blue_text;
}