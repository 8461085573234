@use '../../style_imports/colours' as *;

.options_wrapper {
  font-size: smaller;
  padding: 0.1rem;
}

.progress_prompt {
  border-bottom: 1px solid $light_grey_border;
}

