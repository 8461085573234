@use '../../../style_imports/colours' as *;

.timerange_slider {
  background-color: $benchmarking_light;
}

.timerange_slider-1 {
  background-color: $benchmarking;
}

.timerange_slider_label {
  color: $benchmarking;
}

.timerange_slider_handle {
  color: $benchmarking_light;
  background-color: $benchmarking;
}