@use '../../style_imports/colours' as *;

.tag_shape, .tag_shape_disabled {
  padding-left: 0.5rem;
  padding-right: 0.3rem;
  background-color: $tag_shape_background;
  clip-path: polygon(0.4rem 0, 100% 0, 100% 100%, 0.4rem 100%, 0 50%);
  margin: 0 0.2rem 0.2rem 0;
  display: inline-flex;
}

.tag_shape_disabled {
  background-color: $disabled_tag_shape_background;
}

.tag_short_name {
  color: $lightest_text;
  max-width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag_long_name {
  max-width: none !important;
  overflow: auto !important;
  white-space: normal !important;
}

.close_icon {
  color: $lightest_text;
  flex-shrink: 0;
  &:hover {
    color: $link_text_hover !important;
    cursor: pointer;
  }
}

.user_icon {
  color: $lightest_text;
  &:hover {
    color: $link_text_hover;
  }
}

.expanded_tag_name {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}