@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.block {
  background-color: $tooltip_bg;
  border: 1px solid $tooltip_border;
  border-radius: $border_radius;
  color: $label_text;
  padding: .5rem;
  font-size: 14px;
  font-weight: 400;

  &:active, &:focus {
    outline: none !important;
  }
}

.header {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  color: $dark_text;
}

.content__light {
  color: $grey_text_1;
}

.btn {
  color: $link_text;
  cursor: pointer;

  &:hover {
    color: $link_text_hover;
  }
}

.btn:focus {
  outline: none;
}

.__wide {
  width: 500px !important;
  max-width: 500px !important;
}
