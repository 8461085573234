@use '../../style_imports/colours' as *;

.label {
  font-weight: 700;
  white-space: nowrap;

  &__disabled {
    color: $disabled_txt;
  }
}

.label_inline {
  @extend .label;
  width: 150px;

  flex-grow: 0; // ensure column width static
  flex-shrink: 0;
}

.heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}