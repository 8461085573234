@use '../style_imports/layout' as *;

.content_container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.scroll_bar_space {
  padding: $scrollbar_padding;
}

.include_scrollbar__default {
  overflow: auto;
  @include always_show_y_scrollbar;
}

.include_scrollbar__light {
  overflow: auto;
  @include always_show_light_scrollbar;
}