/*
  Overrides for BasePlotlyXYChart.js
*/
.baseplotlyxychart_default_cursor .js-plotly-plot .plotly .cursor-pointer {
  // Override plotly pointer cursor. This can be useful if clickthroughs are disabled on a chart.
  cursor: default;
}

/*
  Overrides for BaseTreemap.js
*/
.base_treemap_wrapper .js-plotly-plot .plotly .cursor-pointer {
  // Override plotly pointer cursor. This can be useful if clickthroughs are disabled on a chart.
  cursor: default;
}

/*
  Overrides for GrowthTreemap.js
*/
.growth_treemap_legend {
  .tick {
    line {
      visibility: hidden;
    }
  }
}

/*
  Overrides for PieChart.js
*/
.piechart_pointer_cursor .pielayer .slice {
  cursor: pointer;
}

.pielayer .slicetext .textline {
  display: none; // hide the annoying lines that sometimes cut across the text
}