@use '../../style_imports/colours' as *;

.table {
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.field_with_overflow {
  overflow: visible !important;
}

.alert_param_dropdown_button {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  min-width: 4.2rem;
  margin-top: -1px; // where does this pixel come from? not sure but it messes with alignment in the table
  &:hover,
  &:visited,
  &:active,
  &:focus,
  &:disabled {
    // override nasty react-strap defaults
    background-color: transparent;
    border: none;
    outline: none;
  }
}

.frequency_menu {
  width: 10rem;
}

.stopped_alert_row {
  background-color: rgba($system_error, 0.1);
}

.calendar_icon {
  padding-top: 6px !important;
}

.popover {
  background-color: $lightest_bg;
  border: 1px solid $light_grey_border;
  line-height: 1.5rem;
}