@use '../style_imports/colours' as *;

.chart_sets_menu {
  font-size: 14px;
}

$language_menu_width: 10rem;

.language_btn {
  width: $language_menu_width;
}

.language_menu {
  width: $language_menu_width;
}

.tooltip_trigger {
  color: $link_text;

  &:hover {
    color: $link_text_hover;
  }
}