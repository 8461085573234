@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.menu_toggle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $dark_text;
}

.menu {
  background-color: $lightest_bg;
  border: 1px solid $light_grey_border;
  border-radius: $border_radius_small;
}

.filter_menu {
  width: auto;
}