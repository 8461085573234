@use '../../style_imports/colours' as *;
@use '../../style_imports/_layout' as *;

$padding: 0.5rem;

.status {
  height: calc($padding / 2)
}

.controls {
  padding: calc($padding / 2) 0 $padding 0;
}

.button,
.button:hover,
.button:active {
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.menu {
  width: 100%;
}

.second_row {
  min-height: 2rem; // prevent jump
}

.classifier_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.local_classifier_disabled {
  opacity: 0.2;
}

.search_text_area {
  font-size: 0.85rem;
}

.classifier_info_block {
  height: 2rem;
}

.retry_fetch_external_labels_link {
  color: $attention;
  cursor: pointer;
}
