@use '../../style_imports/colours' as *;

.button {
  border: 2px solid $light_grey_border;
  height: 2rem;
  padding: 0 0.5rem;
}

.button_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 8rem;
  text-align: left;
}

.menu {
  min-width: 13rem !important;
}

.item {
  cursor: default !important; // overide reactstrap default
  padding-top: 0;
  padding-bottom: 0;
}