@use '../style_imports/colours' as *;

%tippy-arrow__pseudo {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid;
  display: block;
}

.tippy-arrow {
  width: 1rem;
  height: 1rem;
  color: $tooltip_bg;

  &:before {
    @extend %tippy-arrow__pseudo
  }

  &:after {
    @extend %tippy-arrow__pseudo
  }
}

$border-color__before: $tooltip_bg;
$border-color__after: $tooltip_border;

$position__before: -7px;
$position__after: -8px;

%tippy-arrow__pseudo__top {
  left: 0;
  border-width: .5rem .5rem 0;
  transform-origin: center top;
  border-top-color: $border-color__before;
  bottom: $position__before;
}

%tippy-arrow__pseudo__bottom {
  left: 0;
  border-width: 0 .5rem .5rem;
  transform-origin: center bottom;
  border-bottom-color: $border-color__before;
  top: $position__before;
}

%tippy-arrow__pseudo__left {
  border-width: .5rem 0 .5rem .5rem;
  transform-origin: center left;
  border-left-color: $border-color__before;
  right: $position__before;
}

%tippy-arrow__pseudo__right {
  border-width: .5rem .5rem .5rem 0;
  transform-origin: center right;
  border-right-color: $border-color__before;
  left: $position__before;
}

.tippy-box[data-placement^='top'] > .tippy-arrow {
  bottom: 0;

  &:before {
    @extend %tippy-arrow__pseudo__top;
  }

  &:after {
    @extend %tippy-arrow__pseudo__top;
    bottom: $position__after;
    border-top-color: $border-color__after;
  }
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow {
  top: 0;

  &:before {
    @extend %tippy-arrow__pseudo__bottom;
  }

  &:after {
    @extend %tippy-arrow__pseudo__bottom;
    top: $position__after;
    border-bottom-color: $border-color__after;
  }
}

.tippy-box[data-placement^='left'] > .tippy-arrow {
  right: 0;

  &:before {
    @extend %tippy-arrow__pseudo__left;
  }

  &:after {
    @extend %tippy-arrow__pseudo__left;
    border-left-color: $border-color__after;
    right: $position__after;
  }
}

.tippy-box[data-placement^='right'] > .tippy-arrow {
  left: 0;

  &:before {
    @extend %tippy-arrow__pseudo__right;
  }

  &:after {
    @extend %tippy-arrow__pseudo__right;
    left: $position__after;
    border-right-color: $border-color__after;
  }
}

