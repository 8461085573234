@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.button_group_button,
.button_group_button:focus,
.button_group_button:hover {
  border: 0 !important;
  border-radius: $border_radius_small !important;
  fill: $dark_text;
  color: $dark_text;
  & line {
    stroke: $dark_text;
  }
  &:hover {
    background-color: $btn_hover_bg;
  }
}

.button_group_button__active,
.button_group_button__active:focus,
.button_group_button__active:hover {
  & line {
    stroke: $lightest_text;
  }
  background-color: $item_active !important;
  fill: $lightest_text !important;
  color: $lightest_text !important;
}