@use '../../../style_imports/colours' as *;
@use '../../../style_imports/bespoke_viewer' as *;

$logo_link_width: 3rem;

.footer {
  position: absolute;
  bottom: 0;
  height: $bespoke_footer_height;
  background-color: $benchmarking;
}

.logo_link {
  width: $logo_link_width;
}

.back_button_logo {
  font-size: 1.2em;
  padding-top: 0.1rem;
  padding-right: 0.2rem;
}

.back_button {
  color: $white;
  border: 1px solid $white;
  line-height:1.5rem;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 0.3rem 0 0.2rem;
  height: 1.5rem;
}

.settings_toggle {
  cursor: pointer;
  width: $logo_link_width;
  color: $white;
  font-size: xx-large;
  text-align: right;
  height: 2.5rem;
  &:hover {
    color: $link_text_hover;
  }
  &:disabled {
    color: $white;
    opacity: 0.5;
  }
}

.settings_toggle__disabled {
  cursor: not-allowed;
  color: $disabled_txt !important;
  pointer-events: none;
}

.pager {
  color: $white;
  width: 12rem;
}

.pager_button {
  color: white;
  background-color: $benchmarking !important;
  &:focus {
    color: white;
  }
  &:hover {
    background: $benchmarking_mid !important;
  }
}