@use '../../style_imports/colours' as *;


.image {
  user-select: none;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
}

.image_wrapper {
  display: block;
  padding: 15px;
}

.thumbnail {
  border: 1px solid $family_view_border;
}

.thumbnail__selected {
  border: 1px solid $bright_blue_border !important;
}

.not_found_block {
  cursor: default !important;
  width: 5rem;
  white-space: normal;
  text-align: center;
  background-color: $grey_bg_2;
  display: inline-block;
  vertical-align: middle;
  padding: 1rem;
}