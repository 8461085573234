@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.container {
  position: relative;
}

.input {
  border-radius: $border_radius_small;

  font-weight: 400;

  &::placeholder {
    color: $placeholder_text;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    border-color: $bright_blue_border;
    outline: 1px solid rgba(79, 195, 247, .40);
  }

  &:hover {
    border-color: $dark_grey_border;
    outline: none;
  }
}

.input_addon {
  color: $label_text;
  font-size: 12px;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
}

.input_addon__active {
  opacity: 100;
}

.spinner {
  cursor: default !important;
}