@use '../../style_imports/colours' as *;

.status_mark__vertical {
  width: .25rem;
  border-radius: inherit;
}

.status_mark__horizontal {
  height: 100%;
}

.status_mark__positive {
  background-color: $knn_positive_label;
}

.status_mark__negative {
  background-color: $knn_negative_label;
}

.status_mark__bookmarked {
  background-color: $knn_bookmark_label;
}