@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.item_wrapper {
  cursor: pointer;
  color: $grey_text_3;
  font-size: 15px;
  border-bottom: 1px solid $light_grey_border;
  &:hover .item {
    background-color: $list_item_hover;
    border-radius: $border_radius_small;
  }
}

.item__selected {
  background-color: $list_item_selected;
  cursor: default;
  border-radius: $border_radius_small;

  &:hover {
    background-color: $list_item_selected_hover !important;
  }
}

.status_marker {
  width: .25rem;
  min-width: .25rem;
  max-width: .25rem;
  margin-right: .25rem;
  border-top-left-radius: $border_radius_small;
  border-bottom-left-radius: $border_radius_small;
}

.item_detail_line {
  height: 1.5rem;
  line-height: 1.5rem;
}

.title {
  color: $grey_text_1;
  font-size: 16px;
  font-weight: 700;
}

.title__preview {
  @extend .title;
  @include single_line;
}

.year {
  width: 2.5rem;
  text-align: right;
}

.abstract_preview {
  @include single_line;
}

.owner_preview {
  @include single_line;
}