@use '../../style_imports/colours' as *;


.icon {
  color: $dark_text;
}

.info {
  color: $link_text;
  font-size: 12px;
  font-weight: 700;

  &:hover {
    color: $link_text_hover;
  }
}

.inactive {
  color: $blue_text;
}
