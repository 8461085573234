@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;
@use '../../style_imports/viewer_layout' as *;

.content_wrapper {
  overflow: hidden;
}

.sidebar {
  border-right: 1px solid $light_grey_border;
  overflow: hidden;

  width: 11rem;
  @media (min-width: $sm_breakpoint) {
    width: 15rem;
  }
  @media (min-width: $md_breakpoint) {
    width: 17rem;
  }
}

$list_header_height: 3rem;
$list_section_padding_bottom: .5rem;

.list_header {
  height: $list_header_height;
}

.list {
  height: calc(100% - #{$list_header_height + $list_section_padding_bottom});
  overflow-x: scroll;
  @include always_show_y_scrollbar;
}

.list_section {
  padding-bottom: $list_section_padding_bottom;
}

.params_block {
  overflow: auto;
}

.download_control {
  width: 1rem;
}

.option {
  min-height: 1.5rem;
}
