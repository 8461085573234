@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.report_option {
  font-size: 20px;
  color: $lightest_text;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $link_text_hover;
    text-decoration: none;
  }
}

.report_option__font_size {
  font-size: 1.4rem;
}

.report_option__off {
  color: $grey_text_1;
}

.report_option__on {
  background: $menu_bg;
  color: $link_text;
}

