@use '../../style_imports/colours' as *;

.link {
  cursor: pointer;
  text-decoration: none;
  color: $link_text;

  &:hover {
    color: $link_text_hover;
  }

  &__decoration:hover {
    text-decoration: underline;
  }

  &__no_decoration:hover {
    text-decoration: none;
  }
}

.link__disabled, .link__disabled:hover {
  cursor: not-allowed;
  color: $control_disabled_txt !important;
  fill: $control_disabled_txt !important;
  pointer-events: none;
}

.link__not_selectable {
  user-select: none;
}

.link_not_clickable {
  cursor: default;
}