.header_cell {
  font-weight: bold;
}

.table_y_values {
  text-align: left;
}

.table_column_width__80 {
  width: 80% !important;
}

.table_column_width__40 {
  width: 40% !important;
}

.table_x_values {
  text-align: right;
}

.cell__clickthrough {
  cursor: pointer;
}

.report_deck_value_cell {
  padding: 0.1rem 0.3rem !important;
}