@use '../../../style_imports/colours' as *;
@use '../../../style_imports/_layout' as *;

$actions_wrapper_width: 5rem;

.label {
  color: $grey_text_1;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.phrase_heading {
  padding-right: $actions_wrapper_width;
}

.phrase_row {
  height: 1.8rem;
}

.phrase_input {
  border-radius: 0;
  height: 100%;
  border: 1px solid $light_grey_border;
}

.actions_wrapper {
  width: $actions_wrapper_width;
}

.action_icon {
  color: $blue_grey_text_3;
  font-size: 18px;
}

.colour_picker_popover {
  position: absolute;
  right: 4rem;
  top: 1rem;
  z-index: 2;
}

.colour_picker_cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
