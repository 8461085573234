@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

$basket_header_height: 2.5rem;
$basket_section_space: .5rem;

.basket_header {
  color: $lightest_text;
}

.basket_header_details {
  font-size: smaller;
  color: $grey_bg_1;
}

.basket_section_spacer {
  margin-top: $basket_section_space;
}

.basket_section {
  height: calc((100% - $basket_section_space) / 2);
  position: relative;
  display: block;
  border-bottom: 1px solid $light_grey_border;
}

.basket_section_header {
  color: $dark_text;
  background-color: $grey_bg_2;
  border: 1px solid $light_grey_border;
  height: $basket_header_height;

  & h6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.basket_items_container {
  min-height: 5rem;
  height: calc(100% - $basket_header_height);
  border: 1px solid $light_grey_border;
  border-top: none !important;
  border-bottom: none !important;

  overflow-y: scroll;
  overflow-x: hidden;
  @include always_show_y_scrollbar;
}

.search_term {
  font-weight: 700;
}

.search_term_size {
  font-size: small;
}

.item_wrapper {
  height: 2.5rem;
}

.item_name {
  color: $dark_text;

  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.portfolio_basket_info {
  height: 1.5rem;
  font-size: 12px;
  color: $blue_grey_text_3;
}

.__calculating {
  font-weight: 400;
  font-size: 12px;
}