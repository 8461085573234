@use '../../style_imports/_layout' as *;
@use '../../style_imports/colours' as *;

.container {
  position: relative; // ensures that ghost has same width contraints as target
}

.text_area {
  height: 100%;
  &:hover {
    border-color: $dark_grey_border;
  }

  &:active {
    border-color: $bright_blue_border;
    outline: 1px solid rgba(79, 195, 247, .40);
  }
}

.text_area__clearable {
  padding-right: 1.75rem; // space for the clear icon
}

.text_area__ghost {
  height: auto;
  visibility: hidden;
  position: absolute;
  top: 0;
}

.clear {
  position: absolute;
  top: .5rem;
  right: 0;
  cursor: pointer;
  opacity: 0;
  font-size: 10px;
}

.clear__active {
  opacity: 100;
}