@use '../../style_imports/colours' as *;

.header_wrapper {
  background-color: $grey_bg_1;
  border: 1px solid $light_grey_border;
  border-bottom: none !important;
}

.section_header {
  color: $dark_text;
  background-color: $grey_bg_2;
  border: 1px solid $light_grey_border;
}

.section {
  min-height: 5rem;
  border: 1px solid $light_grey_border;
  border-top: none !important;
}

.feedback_select {
  height: 3.5rem;
}

.message {
  font-size: small;
}