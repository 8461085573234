@use '../../style_imports/colours' as *;
@use '../../style_imports/_layout' as *;

.block, .block:visited, .block:active {
  color: $link_text;
  text-decoration: none !important;

  &:hover {
    color: $link_text_hover;
  }
}