.menu {
  min-width: 4rem !important;
  width: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.button {
  padding: .125rem .5rem;
}

.button_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 3rem;
  text-align: left;
}

.row_container {
  padding-top: 0;
  padding-bottom: 0;
}