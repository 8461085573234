@use '../../style_imports/colours' as *;

.wrapper {
  max-height: 95%;
  overflow: scroll;
}

.item {
  height: fit-content;
  background-color: $lightest_bg;
  box-shadow: .5rem .5rem .5rem -0.25rem rgba($grey_bg_1, 1);
}

.item__org {
  background-color: rgba($family_status_in_force, .5);
}

.icon {
  fill: $link_text;
  height: 1rem;
  width: 1rem;
}

$navigation_height: 0;

.spinner_overlay {
  height: calc(100% - #{$navigation_height});
  position: absolute;
  left: 0;
  right: 0;
  top: $navigation_height;
  bottom: 0;
  background-color: rgba($lightest_bg, .3);
  z-index: 9999;
}
