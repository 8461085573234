@use '../style_imports/colours' as *;

.react-datepicker {
  font-family: inherit;
  // dropshadow is present in some designs but not others; leave it out for consistency for now
  // border: none;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);

  &__header {
    border-bottom: none;
    background-color: $lightest_bg;
  }

  &__day-name {
    font-weight: bold;
  }

  &__month {
    margin-top: 0;
  }

  &__current-month {
    display: none;
  }

  &__navigation-icon {
    &--previous {
      border-right-color: $item_active;
    }
    &--next {
      border-left-color: $item_active;
    }
  }

  &__month-read-view--down-arrow{
    border-top-color: $item_active;
  }

  &__year-read-view--down-arrow{
    border-top-color: $item_active;
  }

  &__close-icon::after {
    background-color: transparent;
    color: $item_active;
    font-size: 30px;
    height: 30px;
    width: 30px;
    padding: 0 1.3rem 0.2rem;
  }

  &__input-container {
    display: inline-flex;
    align-items: center;
  }

  &__calendar-icon {
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
    right: 0;
    color: $dark_text;
    cursor: pointer;
    &__disabled {
      color: $control_disabled_txt;
      cursor: default;
    }
  }

  &__day {
    &:hover {
      background-color: $blue_grey_bg_1;
    }
    &--selected {
      background-color: $item_active;
      &:hover {
        background-color: $item_active;
      }
    }
    &--keyboard-selected {
      // if I click on 1 Dec, then change months, this style is applied to the 1st of the new month
      background-color: $ps_blue_100;
      &:hover {
        background-color: $item_active;
      }
    }
  }
}

