@use '../../style_imports/colours' as *;

.row_container {
  cursor: pointer;
}

.text__single_line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.next_agglom_item {
  border-top: 1px dotted $light_grey_border;
  padding-top: .25rem;
}

.next_agglom_item__stick_to_end {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.label__spotlighted {
  text-shadow: .5px 0 0 $dark_text;
  color: $dark_text
}

.desc_popup {
  max-height: 25rem;
  overflow-y: auto;
}