@use '../../style_imports/_colours' as *;

/* Ensure lines don't wrap, and width stretches properly */
.related_dropdown_menu {
  right: auto;
}

.related_dropdown_menu_item {
  white-space: nowrap;
}

.dropdown_button {
  border: 2px solid $dark_border;
  padding-top: 3px !important;     // workaround to prevent buttons from changing height of header
  padding-bottom: 3px !important;

  background-color: $lightest_bg;
  color: $dark_text;
}

.info {
  color: $blue_grey_text_3;
  &:hover {
    background-color: transparent;
    color: $blue_grey_text_3;
  }
}