@use '../../../style_imports/colours' as *;

.selected_actions {
  border: 1px solid $light_grey_border;
  border-radius: 15px;
  color: $label_text;
  padding: .125rem .25rem;

  line-height: 18px;
}

.selected_count {
  font-weight: bold;
}

.tag_dropdown_toggle {
  margin-left: 1rem;
  cursor: pointer;
  float: right;
  font-size: inherit !important;
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}

.tag_dropdown_toggle_disabled {
  margin-left: 1rem;
}