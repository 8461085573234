@use '../../style_imports/colours' as *;
@use '../../style_imports/_layout' as *;

.table_container {
  overflow: initial !important;
}

.table_cell {
  flex-wrap: nowrap;
  white-space: nowrap !important; // override annoying ReactStrap default.
  background-color: $lightest_bg;
}

.table_cell__classifier_controls {
  transition: none !important; // css borders on hover has a weird glitch if transitions are on
  min-height: 2.256rem;
}

.table_header {
  background-color: $lightest_bg;

  z-index: 1; // Ensures appears above the body rows (but underneath dropdowns, header, etc)

  // For now, header is not scroll-able by user (overflow: hidden).
  // Body is scrollable, and will set the header position programatically.
  // This avoids Safari jitter problems (when listenners on both header/body scroll),
  // there seemed to be some weird feedback occasionally where
  // header scroll triggers body scroll and so  on...
  overflow: hidden;

  scrollbar-width: none;    // hide scrollbar on Firefox
  -ms-overflow-style: none; // hide scrollbar on IE
}

.table_header::-webkit-scrollbar {
  // Hide scrollbar on Chrome/Safari
  width: 0 !important;
  height: 0 !important;
}

.react_table_container .table_header .header_cell {
  // We need to ensure that the body and header
  // have the same scroll width, otherwise scroll behaviour
  // will be weird when we try to sync them.

  // For some reason:
  //     body has overflow 'auto'
  //     header has overflow 'visible'
  // and this can result in the header having 22px extra
  // scroll width. I'm not entirely sure why.

  // So....
  // We override this here, and set header overflow to 'hidden'
  // i.e. setting header header overflow 'auto' would give
  // scroll bars on each field.

  // In order to do this... we need to provide a 3-level descendant selector
  // as ReactTable stupidly sets .ReactTable .rt-thead .rt-th { overflow: visible !important; }
  overflow: hidden !important;
}

.table_row__highlight_on_hover {
  position: relative; // for positioning children absolutely (i.e. Labelling controls etc)
}

.table_cell__selected {
  background-color: $list_item_selected;
}

// Sticky column stuff
.sticky_column {
  position: sticky !important;
  left: 0;
  top: 0;
}

.sticky_column_header {
  background-color: $lightest_bg;
  z-index: 1; // ensure header stays on top
}

.sticky_column_offset_1 {
  left: 0.5rem; // leaves space for a classifier label row marker column
}

.sticky_column_offset_2 {
  left: 2.5rem; // leaves space for a select checkbox column
}

.sticky_column_offset_3 {
  left: 3rem; // leaves space for a label marker and a checkbox
}

// Eval classifier stuff
.labelling_container {
  display: none; // hidden by default
  position: absolute;
  top: 4px;
  right: 3px;
  height: 80%;
  padding: 3px .4rem !important;

  border-radius: $border_radius_small;

  background-color: $lightest_bg; // Reset colour - transparent overlay will handle the colouring.
}

.labelling_container__show {
  display: flex !important;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.suggestions_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user_class_icon {
  width: 1.25rem;
  height: 1.25rem;
}


