.current {
  display: block;
}

.next {
  display: none;
}

.wrapper {
  width: 1.2rem;
  text-align: center;

  &:hover {
    .current {
      display: none;
    }

    .next {
      display: block;
    }
  }
}

