@use '../../style_imports/layout' as *;

.block {

  padding: $gutter_padding_xs;
  width: 100%;

  @media (min-width: $sm_breakpoint) {
    padding-right: $gutter_padding_md;
    padding-left: $gutter_padding_md;
  }

  @media (min-width: $lg_breakpoint) {
    padding-right: $gutter_padding_md;
    padding-left: $gutter_padding_sm;
    padding-top: $gutter_padding_md;
    width: 30%;
  }
}
