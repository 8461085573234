@use '../style_imports/colours' as *;

.track {
  background-color: $grey_bg_2;
  height: 18px;
  margin: 2px 0; /* make up the total height here, so that it is vertically centred */
}

.track-1 {
  /* This is the area between the two handles */
  background-color: $grey_bg_1;
  height: 100%;
  margin: 0;
}

.single-track {
  /*
  The ReactSlider library always adds a `.track-1` class to the second track.
  This is fine for double-handled sliders (i.e. that show a from-to range).
  But it breaks single handle sliders.
  So this workaround renames `.track` to `.single-track`.
  The library still adds class `.single-track-1` to the second track,
  but since that doesn't exist, everything renders ok.
  */
  @extend .track;
}