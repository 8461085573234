@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.lozenge {
  font-weight: $font_weight_demi_bold;
  font-size: 12px;
  line-height: 18px;
  border: $border_thickness solid $light_grey_border;
  border-radius: 15px;
  color: $label_text;
  padding: .125rem .25rem;
}

.remove_btn {
  font-size: 10px;
}