@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.block {
  border: 1px solid $light_grey_border;
  border-radius: $border_radius_small;
  color: $blue_grey_text_3;
}

.stop_icon {
  color: $system_error;
}

.warning_icon {
  color: $system_warning;
}

.info_icon {
  color: $info;
}
