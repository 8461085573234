.dropdown_toggle {
  padding: .125rem .5rem;
  font-size: inherit; // override annoying large bootstrap default
}

.dropdown_toggle_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 5.5rem;
  text-align: left;
}

.dropdown_menu {
  min-width: 8rem;
}