@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.header {
  border: none !important;
  height: $modal_header_height;
}

.modal_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  padding: $modal_content_padding;
  border-radius: $border_radius;
}

.footer {
  border-top: 1px solid $light_grey_border
}

.modal__wide {
  max-width: 88%;
  margin: 1rem auto;
}

.modal_scroll_container {
  height: 90%;
}

.modal_scroll_content {
   height: 100%;
}

.modal_scroll_body {
  height: 100%;
  overflow-y: scroll;

  @include always_show_y_scrollbar;
}

.modal__sm {
  max-width: 512px;
}

.modal__md {
  max-width: 768px;
}

.modal__lg {
  max-width: 1024px
}