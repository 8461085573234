@use '../../style_imports/colours' as *;

.block {
  word-break: break-word;
}

.claim_entry_state_dependent {
  color: $grey_text_3;

  i {
    font-style: unset;
    font-family: Lato !important;
    -webkit-font-smoothing: inherit;
  }
}

.claim_entry_inner {
  div {
    margin: 0.5rem 0;
  }
}