@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

$tile_header_height: $tile_header_height;

.pane_heading {
  color: $dark_text;
  font-size: 21px;
  height: 2rem;
}

.heading {
  color: $dark_text;
}