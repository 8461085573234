@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.block {
  background-color: $lightest_bg !important;
}

.params_block {
  width: 15rem;
  @media (min-width: $lg_breakpoint) {
    width: 25rem;
  }
}
