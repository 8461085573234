@use '../../style_imports/colours' as *;

.date_input {
  width: 7.8rem;
  background-color: transparent;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}