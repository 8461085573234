@use './layout' as *;

$report_container_padding_y: 3.5rem;

$dataset_selector_width: 21rem;

$report_title_bar_height: 3.5rem;
$report_controls_bar_height: 2.5rem;

$dataset_thumbnail_dim: 8.5rem;
$dataset_group_dim: 18.25rem;
$dataset_group_ribbon_width: 12rem;

$thumbnail_large_height: 7rem;
$chart_default_height: 31rem;

$data_container_sticky_top_starting_point: calc($header_height_xl + (2* $report_controls_bar_height));