@use '../style_imports/colours' as *;

// Currently react-spinner does not allow us to inject any css classes.
// So the only way to change it's colour is by creating global descendant selectors.

.react-spinner_bar {
  background-color: black; // default
}

.cipher-spinner-light-1 .react-spinner_bar {
  background-color: $dark_bg_3;
}

.spinner-pale .react-spinner_bar {
  background-color: $light_blue_text;
}