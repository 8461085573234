@use '../../style_imports/_colours' as *;

.block {
  border-bottom: 1px solid $light_grey_border;
}

.row_container {
  width: 100%;
}

.search_container {
  position: relative;
}

.search_input,
.search_input:focus {
  height: 2rem;
  color: $grey_text_1;
  width: 100%;
}

.clear_search_input {
  position: absolute;
  right: 6px;
  top: 6px;
}

.options_menu_container {
  position: relative;
  left: 0.5rem;
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}

.options_menu {
  min-width: 12rem;
}

.count_filter_label {
  color: $label_text;
  cursor: default;
}

.count_filter_label_prefix {
  font-size: small;
  display: inline-block;
  width: 5.5rem; // so that the proceeding input boxes are vertically aligned
  margin-right: 0.25rem;
}

.count_input {
  max-width: 2rem;
}

.count_filter_submit {
  font-size: small;
  margin-left: 0.5rem;
}

.filter_info {
  font-weight: bolder;
}

.select_all_row {
  cursor: pointer;
}

.filtered_options__middle {
  border-left: 1px solid $light_grey_border;
  border-right: 1px solid $light_grey_border;
}

.spotlight_control {
  width: 1.5rem;
  text-align: center;
}
