@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.section {
  color: $label_text;
}

.first_section_header {
  height: 3rem;
}

.subsequent_section_header {
  border-top: 2px solid $light_grey_border;
  padding-top: 2rem;
  margin-top: 1rem;
  height: 5rem;
}