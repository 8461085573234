@use '../../style_imports/_colours' as *;

.banner {
  background-color: $blue_grey_bg_1;
  color: $grey_text_1;
  min-width: 100%;
  margin-bottom: 0.8rem;
}

.banner_title {
  margin-bottom: 0.6rem;
}

.banner_logo {
  color: transparent;
  background-size: 3rem;
  height: 3rem;
  margin-right: 0.6rem;
  width: 3rem;
  background-image: url('../../static/images/animated_bar_chart.gif');

  vertical-align: top;
  display: inline-block;
}

.notify_checkbox_container {
  height: 1.7rem;
}