@use '../../style_imports/_colours' as *;

.label_container {
  color: $blue_grey_text_3;
  cursor: pointer;
  margin-bottom: 0;
}

.label_container__disabled {
  color: $disabled_selector_label;
  cursor: default;
}

.__disabled {
  color: $disabled_selector_label;
}