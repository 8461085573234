@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

$controls_block_xs_height: 100px;
$controls_block_md_height: 52px;
$selector_block_height: 136px;

.images_modal {
  height: 95vh;
  overflow: hidden;
}

.images_modal__body {
  padding: 0;
  margin: 0;
}

.image_controls_block {
  top: 0;
  height: $controls_block_md_height;

  @media screen and (orientation:portrait) and (max-width: $sm_breakpoint) {
    height: $controls_block_xs_height;
  }
}

.image_controls {
  min-width: 173px;
  @media (max-width: $sm_breakpoint) {
    min-width: 0;
    width: auto
  }
}

.selected_image_block__portrait {
  overflow: hidden;
  width: 100%;
  top: $controls_block_xs_height;

  @media (min-width: $md_breakpoint) {
    top: $controls_block_md_height;
  }
}

.selected_image_block__landscape {
  overflow: hidden;
  width: calc(100% -  #{$selector_block_height});
  top: $controls_block_xs_height;

  @media (min-width: $md_breakpoint) {
    top: $controls_block_md_height;
  }
}

.selected_image_wrapper {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: center;
  height: 100%;
  width: 100%;

  @include always_show_scrollbar;
}

.image_selector_block__portrait {
  width: 100%;
  bottom: 0;
  height: $selector_block_height;
}

.image_selector_block__landscape {
  overflow-x: scroll;
  width: $selector_block_height;
  top: $controls_block_xs_height;

  @media (min-width: $md_breakpoint) {
    top: $controls_block_md_height;
  }
}

.image_wrapper {
  display: inline-block;
  padding: 15px;

  transform-origin: top left;
}

.image__rotate_90 {
  transform: rotate(90deg) translateY(-100%);
}

.image__rotate_180 {
  transform: rotate(180deg) translate(-100%, -100%);
}

.image__rotate_270 {
  transform: rotate(270deg) translateX(-100%);
}

.image__selected {
  border: 1px solid $navigation_marker;
}
