@use '../../../style_imports/colours' as *;
@use '../../../style_imports/layout' as *;

.no_fixed_max_width {
  max-width: none !important;
}

.one_line_text {
  flex-wrap: nowrap;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.tooltip span:hover {
  display: block
}

.warn {
  background-color: rgba($match_ambiguous, 0.2) !important;
}

.error {
  background-color: rgba($match_not_found, 0.2) !important;
}

.controls_wrapper {
  height: 3rem;
  top: 0;
  background-color: $lightest_bg;
  z-index: 1010;
}

.family_details_top {
  position: sticky;
  top: 0;
}
