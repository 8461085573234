@use '../../style_imports/colours' as *;

.drag_btn {
  position: absolute;
  bottom: 1rem;
  left: 0.5rem;
  border: none;

  background-color: rgba($lightest_bg, 0.2);
  color: $link_text;

  &:hover {
    background-color: rgba($lightest_bg, 0.8);
    color: $link_text_hover;
  }
}

.item__active {
  background-color: $light_blue_bg_1
}

.item__over {
  background-color: $blue_grey_bg_1;
}
