@use '../../../style_imports/colours' as *;
@use '../../../style_imports/_layout' as *;

.menu_title {
  font-size: 1rem !important;
}

.colour_menu_item {
  border: 1px solid transparent !important;
  border-radius: 0 !important;

  &:hover {
    border-color: $dark_border !important;
  }
}
