@use '../../../style_imports/colours' as *;
@use '../../../style_imports/_layout' as *;

$patent_card_line_height: 18px;
$heading_line_height: 20px;
$footer_line_height: 36px;
$patent_card_num_lines: 16;
$patent_card_extra_header_lines: 2;

$padding: 1rem;
$border_thickness: 1px;

$title_height: 2 * $heading_line_height;
$abstract_height: 8 * $patent_card_line_height;
$details_height: $patent_card_line_height;
$footer_height: calc($footer_line_height + $padding);

$card_height: calc($title_height + $abstract_height + $details_height + $footer_height + 4 * #{$padding} + 2 * #{$border_thickness} );

.container {
  font-size: 15px;
  font-weight: 400;
  line-height: $patent_card_line_height;
  color: $grey_text_1;

  border-radius: $border_radius;
  border: $border_thickness solid $family_view_border;

  position: relative;

  min-height: $card_height;
}

.clickable_container {
  border-top-left-radius: $border_radius;
  border-top-right-radius: $border_radius;
  &:hover {
    background-color: $list_item_hover;
  }
}

.clickable_container__selected {
  background-color: $list_item_selected;
  &:hover {
    background-color: $list_item_selected_hover;
  }
}

.extra_header_inner_container {
  padding: $padding;
}

.status_marker {
  height: calc($padding / 2);
}

.body_container_inner {
  padding: calc($padding / 2) $padding $padding;
}

.footer {
  border-top: 1px solid $family_view_border;
  height: $footer_height;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.footer__inner {
  padding: 0 $padding;
}

.title {
  font-size: 16px;
  line-height: $heading_line_height;
  height: $title_height;
  overflow-y: hidden;
  font-weight: 700;
}

.abstract {
  margin-top: $padding;
  height: $abstract_height;
  overflow-y: hidden;
}

.body_bottom_row {
  margin-top: $padding;
  height: $details_height;
}

.owners {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: $details_height;
  max-width: 50%;
}

.cpc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: $details_height;
  max-width: 40%;
}

.border_left {
  border-left: 1px solid $light_grey_border;
}

.border_right {
  border-right: 1px solid $light_grey_border;
}

.score_label {
  color: $grey_text_2;
}

.idx_label,
.idx_label:hover {
  color: $grey_text_2;
  user-select: none;
}

.idx_link {
  color: $grey_text_2;
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
}

.labelling_controls_container {
  position: absolute;
  height: 100%;
  right: 0.5rem;
  padding-left: 0.125rem;
}

.labelling_controls_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.positive {
  background-color: $classifier_label_positive;
}

.negative {
  background-color: $classifier_label_negative;
}

.ignore {
  background-color: $classifier_label_ignore;
}

.test_positive {
  border: 1px solid $classifier_label_test_positive;
  background: repeating-linear-gradient(
    45deg,
    $classifier_label_test_positive,
    $classifier_label_test_positive 5px,
    $lightest_bg 5px,
    $lightest_bg 10px
  );
}

.test_negative {
  border: 1px solid $classifier_label_test_negative;
  background: repeating-linear-gradient(
    45deg,
    $classifier_label_test_negative,
    $classifier_label_test_negative 5px,
    $lightest_bg 5px,
    $lightest_bg 10px
  );
}

.container__page_control {
  background-color: $grey_bg_2;
  cursor: pointer;
  &:hover {
    background-color: $list_item_hover;
  }
}

.page_control_text {
  color: $dark_text;
  font-size: 16px;
  user-select: none;
}

.page_control_body {
  margin: 0 auto;
}

.page_control_overlay_icon {
  color: $dark_text;
  font-size: 64px;
}

.container__invisible {
  box-shadow: none;
  border-color: transparent;

  &:hover {
    background-color: transparent !important;
  }
}

.classifier_score_note {
  font-weight: bold;
}

.user_class_icon {
  width: 1.5rem;
  height: 1.5rem;
}

.training_status {
  border-top-left-radius: $border_radius;
  border-top-right-radius: $border_radius;
}

.knn_status {
  border-top-left-radius: $border_radius;
  border-top-right-radius: $border_radius;
}