@use '../../style_imports/colours' as *;

.block {
  width: 1.6rem;
  height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.block__enabled {
  &::after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: all 0.2s ease 0s;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    transform-origin: center center;
    background-color: $light_grey_border;
  }

  &:hover::after {
    opacity: 0.15;
  }
}

.input {
  position: absolute;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.radiobox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  position: relative;
  border: 2px solid $light_grey_border;
  &__disabled {
    border-color: $disabled_selector;
  }
  &__disabled_dark {
    border-color: $control_disabled_txt;
  }
}

.radiobox_inner {
  border-radius: 10px;
  width: .5rem;
  height: .5rem;
  opacity: 0;
  background-color: $input_active;
  &__dark {
    background-color: $lightest_text;
  }
}

.__checked {
  border: 2px solid $input_active;

  &_dark {
    border-color: $lightest_text;
  }

  & .radiobox_inner {
    opacity: 1 !important;
  }
}

.block__normal {
  height: 1rem;
  width: 1rem;
  top: 1px;

  &.__checked:after {
    left: 4px;
    top: 2px;
    width: 5px;
    height: 8px;
  }
}

.block__small {
  height: .7rem;
  width: .7rem;
  &.__checked:after {
    left: 2px;
    top: 1.5px;
    width: 5px;
    height: 5px;
  }
}
