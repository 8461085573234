@use '../../style_imports/colours' as *;

.intro_wrapper {
  height: auto;
}

.input_wrapper {
  background-color: $grey_bg_2;
}

.progress_prompt {
  border-bottom: 1px solid $light_grey_border;
}
