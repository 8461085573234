@use '../../style_imports/colours' as *;

.feedback_container {
  color: $system_error;
  align-items: baseline;
  font-size: 16px;
  margin-top: 0.1rem;
}

.feedback_text {
  font-size: 14px;
  margin-left: 0.4rem;
}

.form_feedback__visible {
  display: block !important;
}