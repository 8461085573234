@use 'src/style_imports/colours' as *;
@use 'src/style_imports/layout' as *;

.block {
  overflow-x: auto;
}

.groups_wrapper {
  border-right: 1px solid $light_grey_border;
}
