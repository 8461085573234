@use '../../style_imports/colours' as *;

$opacity_bkg: 0.2;
$tooltip_opacity_bkg: 0.2;
$border_opacity: 1;

.portfolio_size_spec {
  background-color: rgba($portfolio_size_spec, $opacity_bkg) !important;
}

.portfolio_size_spec_tooltip {
  background-color: rgba($portfolio_size_spec, $tooltip_opacity_bkg) !important;
}

.portfolio_size_spec_tooltip_overlay:hover {
  background-color: rgba($portfolio_size_spec, $tooltip_opacity_bkg) !important;
}

.portfolio_size_spec_thumbnail {
  &:hover {
    background-color: rgba($portfolio_size_spec, $opacity_bkg) !important;
  }
}

.portfolio_size_marker {
  background-color: $portfolio_size_spec !important;
}

.portfolio_size_border {
  border-color: rgba($portfolio_size_spec, $border_opacity) !important;
}

.portfolio_trends_spec {
  background-color: rgba($portfolio_trends_spec, $opacity_bkg) !important;
}

.portfolio_trends_spec_tooltip {
  background-color: rgba($portfolio_trends_spec, $tooltip_opacity_bkg) !important;
}

.portfolio_trends_spec_tooltip_overlay:hover {
  background-color: rgba($portfolio_trends_spec, $tooltip_opacity_bkg) !important;
}

.portfolio_trends_spec_thumbnail {
  &:hover {
    background-color: rgba($portfolio_trends_spec, $opacity_bkg) !important;
  }
}

.portfolio_trends_marker {
  background-color: $portfolio_trends_spec !important;
}

.portfolio_trends_border {
  border-color: rgba($portfolio_trends_spec, $border_opacity) !important;
}

.patenting_activity_spec {
  background-color: rgba($patenting_activity_spec, $opacity_bkg) !important;
}

.patenting_activity_spec_tooltip {
  background-color: rgba($patenting_activity_spec, $tooltip_opacity_bkg) !important;
}

.patenting_activity_spec_tooltip_overlay:hover {
  background-color: rgba($patenting_activity_spec, $tooltip_opacity_bkg) !important;
}

.patenting_activity_spec_thumbnail {
  &:hover {
    background-color: rgba($patenting_activity_spec, $opacity_bkg) !important;
  }
}

.patenting_activity_marker {
  background-color: $patenting_activity_spec !important;
}

.patenting_activity_border {
  border-color: rgba($patenting_activity_spec, $border_opacity) !important;
}

.geo_spec {
  background-color: rgba($geo_spec, $opacity_bkg) !important;
}

.geo_spec_tooltip {
  background-color: rgba($geo_spec, $tooltip_opacity_bkg) !important;
}

.geo_spec_tooltip_overlay:hover {
  background-color: rgba($geo_spec, $tooltip_opacity_bkg) !important;
}

.geo_spec_thumbnail {
  &:hover {
    background-color: rgba($geo_spec, $opacity_bkg) !important;
  }
}

.geo_marker {
  background-color: $geo_spec !important;
}

.geo_border {
  border-color: rgba($geo_spec, $border_opacity) !important;
}

.age_spec {
  background-color: rgba($age_spec, $opacity_bkg) !important;
}

.age_spec_tooltip {
  background-color: rgba($age_spec, $tooltip_opacity_bkg) !important;
}

.age_spec_tooltip_overlay:hover {
  background-color: rgba($age_spec, $tooltip_opacity_bkg) !important;
}

.age_spec_thumbnail {
  &:hover {
    background-color: rgba($age_spec, $opacity_bkg) !important;
  }
}

.age_marker {
  background-color: $age_spec !important;
}

.age_border {
  border-color: rgba($age_spec, $border_opacity) !important;
}

.cost_spec {
  background-color: rgba($cost_spec, $opacity_bkg) !important;
}

.cost_spec_tooltip {
  background-color: rgba($cost_spec, $tooltip_opacity_bkg) !important;
}

.cost_spec_tooltip_overlay:hover {
  background-color: rgba($cost_spec, $tooltip_opacity_bkg) !important;
}

.cost_spec_thumbnail {
  &:hover {
    background-color: rgba($cost_spec, $opacity_bkg) !important;
  }
}

.cost_marker {
  background-color: $cost_spec !important;
}

.cost_border {
  border-color: rgba($cost_spec, $border_opacity) !important;
}

.dispute_spec {
  background-color: rgba($dispute_spec, $opacity_bkg) !important;
}

.dispute_spec_tooltip {
  background-color: rgba($dispute_spec, $tooltip_opacity_bkg) !important;
}

.dispute_spec_tooltip_overlay:hover {
  background-color: rgba($dispute_spec, $tooltip_opacity_bkg) !important;
}

.dispute_spec_thumbnail {
  &:hover {
    background-color: rgba($dispute_spec, $opacity_bkg) !important;
  }
}

.dispute_marker {
  background-color: $dispute_spec !important;
}

.dispute_border {
  border-color: rgba($dispute_spec, $border_opacity) !important;
}

.stats_spec {
  background-color: rgba($stats_spec, $opacity_bkg) !important;
}

.stats_spec_tooltip {
  background-color: rgba($stats_spec, $tooltip_opacity_bkg) !important;
}

.stats_spec_tooltip_overlay:hover {
  background-color: rgba($stats_spec, $tooltip_opacity_bkg) !important;
}

.stats_spec_thumbnail {
  &:hover {
    background-color: rgba($stats_spec, $opacity_bkg) !important;
  }
}

.stats_marker {
  background-color: $stats_spec !important;
}

.stats_border {
  border-color: rgba($stats_spec, $border_opacity) !important;
}

.score_spec {
  background-color: rgba($score_spec, $opacity_bkg) !important;
}

.score_spec_tooltip {
  background-color: rgba($score_spec, $tooltip_opacity_bkg) !important;
}

.score_spec_tooltip_overlay:hover {
  background-color: rgba($score_spec, $tooltip_opacity_bkg) !important;
}

.score_spec_thumbnail {
  &:hover {
    background-color: rgba($score_spec, $opacity_bkg) !important;
  }
}

.score_marker {
  background-color: $score_spec !important;
}

.score_border {
  border-color: rgba($score_spec, $border_opacity) !important;
}

.pvix_spec {
  background-color: rgba($pvix_group, $opacity_bkg) !important;
}

.pvix_spec_tooltip {
  background-color: rgba($pvix_group, $tooltip_opacity_bkg) !important;
}

.pvix_spec_tooltip_overlay:hover {
  background-color: rgba($pvix_group, $tooltip_opacity_bkg) !important;
}

.pvix_spec_thumbnail {
  &:hover {
    background-color: rgba($pvix_group, $opacity_bkg) !important;
  }
}

.pvix_marker {
  background-color: $pvix_group !important;
}

.pvix_border {
  border-color: rgba($pvix_group, $border_opacity) !important;
}

.selected_spec {
  background-color: rgba($selected_group, $opacity_bkg) !important;
}

.selected_spec_tooltip {
  background-color: rgba($selected_group, $tooltip_opacity_bkg) !important;
}

.selected_spec_tooltip_tooltip_overlay:hover {
  background-color: rgba($selected_group, $tooltip_opacity_bkg) !important;
}

.selected_marker {
  background-color: $selected_group !important;
}

.selected_border {
  border-color: rgba($selected_group, $border_opacity) !important;
}

.selected_spec_thumbnail {
  fill: $selected_group;
  & line {
    stroke: $selected_group;
  }
}
