@use '../../style_imports/colours' as *;

.label {
  font-size: 15px;
  font-weight: 700;
  color: $label_text;
}

.invalid {
  color: $system_error;
}