@use '../../style_imports/viewer_layout' as *;

.block {
  height: $thumbnail_large_height;
  width: $thumbnail_large_height;
}

.label {
  position: absolute;
  top: calc(#{$thumbnail_large_height} / 2);
}