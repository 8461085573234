.wrapper {
  text-align: left;
}

.icon_wrapper {
  width: 4rem;
}

.address_wrapper {
  width: 18rem;
  white-space: normal;
}
