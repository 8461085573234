@use '../../../style_imports/colours' as *;

.edit_report_tags_dropdown_toggle {
  color: $link_text;
  cursor: pointer;
  float: right;
  font-size: inherit !important;
  :hover {
    color: $link_text_hover
  }
}

.edit_report_tags_dropdown_toggle_disabled {
  color: $control_disabled_txt;
  cursor: auto;
  :hover {
    color: $control_disabled_txt;
  }
}