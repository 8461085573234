@use '../../../style_imports/layout' as *;
@use '../../../style_imports/colours' as *;

.btn {
  width: 2.5rem;
  height: 1.3rem;
  color: $benchmarking;
  opacity: 0.8;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
  @media (min-width: $sm_breakpoint) {
    font-size: 12px;
    width: 3.5rem;
  }
  &:hover {
    background-color: $benchmarking_light;
    color: $benchmarking;
  }
  &:active {
    border: $bright_blue_border 1px solid !important;
    outline: $bright_blue_border 1px solid !important;
  }
}

.btn__active {
  background-color: $benchmarking !important;
  opacity: 1;
  color: $lightest_text !important;
  text-shadow: .4px 0 0 $lightest_text;
  cursor: default;
}

.btn__header_active {
  border: $benchmarking_light 1px solid;
}

.btn__header_inactive {
  color: $lightest_text;
}