@use '../../style_imports/colours' as *;

.marker {
  height: 100%;
  width: .25rem;
  border-radius: inherit;
}

.positive {
  background-color: $classifier_label_positive;
}

.negative {
  background-color: $classifier_label_negative;
}

.ignore {
  background-color: $classifier_label_ignore;
}

.test_positive {
  border: 1px solid $classifier_label_test_positive;
  background: repeating-linear-gradient(
    45deg,
    $classifier_label_test_positive,
    $classifier_label_test_positive 5px,
    $lightest_bg 5px,
    $lightest_bg 10px
  );
}

.test_negative {
  border: 1px solid $classifier_label_test_negative;
  background: repeating-linear-gradient(
    45deg,
    $classifier_label_test_negative,
    $classifier_label_test_negative 5px,
    $lightest_bg 5px,
    $lightest_bg 10px
  );
}

