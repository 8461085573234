@use '../../style_imports/colours' as *;

.tag_shape {
  padding-left: 0.5rem;
  padding-right: 0.3rem;
  background-color: $link_text;
  clip-path: polygon(0.4rem 0, 100% 0, 100% 100%, 0.4rem 100%, 0 50%);
  margin: 0 0.2rem 0.2rem 0;
  display: inline-flex;
  cursor: pointer;

  &:hover {
    background-color: $link_text_hover;
  }
}

.tag_name {
  color: $lightest_text;
  max-width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}