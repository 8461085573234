@use '../../style_imports/colours' as *;

.block {
  background-color: rgba($card_body, .4)
}

.input {
  font-weight: 700;
  font-size: 16px;

  &::placeholder {
    font-style: italic;
    color: $blue_text;
  }
}

.tool_link {
  color: $lightest_text;
  text-decoration: none !important;
  .wrapper:hover & {
    text-decoration: none !important;
  }
}

.tool_link_label {
  .wrapper:hover & {
    text-decoration: underline !important;
  }
}

.tool_link_icon {
  font-size: 20px;
}