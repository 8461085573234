@use '../../style_imports/viewer_layout' as *;

.block {
  padding-left: 10px;
  margin-top: 1rem;
  min-height: 20px;
}

.block__thumbnail {
  height: $thumbnail_large_height;
  padding-left: 5px;
}