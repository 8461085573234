.notes_block {
  min-width: 10rem;
  max-width: 20rem;
  font-size: x-small;
}

.edit {
  position: absolute;
  bottom: 0;
  right: 0;
}