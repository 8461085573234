
.error_message {
  margin-left: 0.3rem;
}

.dropdown {
  margin: 0.3rem;
}

.dropdown_content {
  width: max-content;
}

.button {
  padding: .125rem .5rem;
  min-width: 5.5rem;
}

.label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}