@use '../../../style_imports/colours' as *;
@use '../../../style_imports/layout' as *;
@use '../../../style_imports/bespoke_viewer' as *;

.header {
  height: $bespoke_header_height;
  color: $lightest_text;
}

.header__benchmarking {
  background-color: $benchmarking;
}

.menu {
  width: 15rem;
}

.menu_toggle {
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: $link_text_hover;
  }
}

.filters_toggle {
  font-size: 20px;
  &:hover {
    color: $link_text_hover;
  }
  cursor: pointer;
}

.title {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: $sm_breakpoint) {
    font-size: 18px;
  }
}

.created_at {
  font-size: 10px;
  @media (min-width: $sm_breakpoint) {
    font-size: 12px;
  }
}

.disabled {
  color: $control_disabled;
  pointer-events: none;
}