@use '../../style_imports/colours' as *;

.pane {
  background-color: $grey_bg_2;
  color: $blue_grey_text_1;
  font-size: 16px;
  font-weight: 400;
}

.pane__light {
  background-color: $lightest_bg;
}

.text__normal {
  font-size: inherit;
}
