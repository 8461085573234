@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;
@use '../../style_imports/viewer_layout' as *;

$group_selector_height: 3rem;

.marker {
  width: .25rem;
}

.group_wrapper {
  border: 1px solid $light_blue_border;
  border-right: none;
  width: $dataset_group_ribbon_width;

  height: 100%;
  position: relative;
}

.group_wrapper__compact {
  width: $dataset_group_dim;
  border-radius: $border_radius_small;
}

.group_wrapper__ribbon {
  height: calc(100% - #{$group_selector_height});
  overflow: hidden;
}

.group__compact {
  padding: .25rem;
  height: $dataset_group_dim;

  overflow: hidden;
  border-radius: $border_radius_small;
}

.group__scrollable {
  overflow: scroll;
  padding-left: .25rem;
  padding-right: .25rem;
  @include always_show_y_scrollbar;
}

.group__bg {
  background-color: $lightest_bg;
}

.group__no_bg {
  background-color: transparent;
}

.name {
  font-size: 15px;
  font-weight: 700;
  color: $grey_text_1;
  cursor: pointer;
}

.group_selector {
  border-bottom: 1px solid $light_blue_border;
  height: $group_selector_height;
  align-content: center;
}

.group_selector_btn {
  border: 2px solid $dark_border;
  width: 100%;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.show_more_btn {
  cursor: pointer;
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}

.expand_control {
  width: 3rem;
}

.help_link {
  &:hover {
    text-decoration: none;
  }
}

.show_selected_control__mode_text {
  text-transform: capitalize;

  @media (min-width: $sm_breakpoint) {
    text-transform: none;
  }
}

$group_header_height: 1.2rem;

.group_heading {
  height: $group_header_height;
  font-size: 12px;
  color: $dataset_header;
}

.thumbnail_wrapper {
  width: $dataset_thumbnail_dim;
  height: $dataset_thumbnail_dim;
}

.thumbnail_full_view_wrapper {
  height: calc($dataset_thumbnail_dim + $group_header_height + .25rem);
}

.thumbnail_full_view {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav_wrapper {
  border-right: 1px solid $light_blue_border;
}

.nav_btn {
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 1.5rem !important;
  width: 1.5rem !important;

}
