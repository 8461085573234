@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;
@use '../../style_imports/viewer_layout' as *;

.block {
  width: $dataset_thumbnail_dim;
  height: $dataset_thumbnail_dim;
  cursor: pointer;
  overflow: hidden;
  border-radius: $border_radius_small;

  &:hover {
    .add_or_remove_link {
      display: block;
    }

    .selected_icon {
      display: none;
    }
  }
}

.__zoomed {
  background-color: $lightest_bg;
  border: $border_thickness solid;
}

.show_more {
  border: 1px solid $light_blue_border;
  border-radius: $border_radius_small;
  color: $blue_text;
  font-size: 15px;
}

.no_items_block {
  height: 8.5rem;
}

.show_more__count {
  font-size: 24px;
  color: $link_text;
  font-weight: 700;
}

.name_wrapper {
  font-size: 12px;
  font-weight: 700;
  color: $dark_text;
}

.slice {
  color: $blue_text;
}

.slice__disabled {
  color: $grey_text_2;
}

.subject {
  font-weight: bolder;
  color: $dataset_header;
  white-space: nowrap;
}

.spec_header_control {
  position: absolute;
  line-height: initial;
  bottom: 5px;
  right: 5px;
}

.selected_icon {
  display: block;
}

.add_or_remove_link {
  display: none;

  &:hover {
    text-decoration: none;
  }
}

.icon {
  color: $dark_thumbnail;
  fill: $dark_thumbnail;
  & line {
    stroke: $dark_thumbnail;
  }
}