@use '../../style_imports/colours' as *;
@use '../../style_imports/_layout' as *;

.block__thumbnail {
  padding-top: .5rem;
  overflow: hidden;
}

.table {
  table-layout: fixed;
  margin: 0 auto;
  font-size: 0.9rem;
}

.cell {
  padding: 0.25rem;
  border: 1px solid $lightest_bg;
}

.cell__thumbnail {
  height: 1rem;
  width: 1rem;
}

.cell__col_header {
  height: 7.5rem;
  width: 3.75rem;
  overflow: visible;
  text-align: left;
  transform: translate(-1.125rem, -0.25rem) rotate(320deg);
  vertical-align: bottom;
  white-space: nowrap;
  font-weight: normal;
}

.cell__row_header {
  font-weight: normal;
}

.cell__col_first {
  width: 15rem;
}

.cell__value {
  text-align: right;
  vertical-align: middle;
}

.cell__clickthrough {
  cursor: pointer;
}

.cell__row_total {
  // Not sure why, but 1px does not show, hence 2px here.
  border-top: 2px solid $light_grey_border;
}

.cell__column_total {
  // Not sure why, but 1px does not show, hence 2px here.
  border-left: 2px solid $light_grey_border;
}

.cell__spacer_column {
  width: 0.5rem
}
