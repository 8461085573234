.block {
  display: inline-block;
}

.spinner__xs {
  // NOTE: the animation is not ideal at this size (the "glow" effect doesn't really work).
  // But if the spinner is only being shown for a short time it's not really noticable.
  width: 1rem;
  height: 1rem;
  top: 0.5rem;
  left: 0.5rem;
}

.spinner__xs {
  width: .75rem;
  height: .75rem;
  top: 0.5rem;
  left: 0.5rem;
}

.spinner__sm {
  width: 1.3rem;
  height: 1.3rem;
  top: 0.65rem;
  left: 0.65rem;
}

.spinner__md {
  width: 2rem;
  height: 2rem;
  top: 1rem;
  left: 1rem;
}

.spinner__lg {
  width: 4rem;
  height: 4rem;
  top: 2rem;
  left: 2rem;
}

.spinner__xl {
  width: 8rem;
  height: 8rem;
  top: 4rem;
  left: 4rem;
}

.download_text {
  font-size: 12px;
  white-space: nowrap;
}
