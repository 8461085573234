// override checkbox tree highlighting (this fights with search highlighting) and other unwanted styles
.rct-node-clickable {
  cursor: default;
  display: -webkit-flex; /* Safari */
  display: flex;

  &:hover {
    background: transparent;
  }

  &:focus {
    outline: 0;
    background: transparent;
  }
}

.react-checkbox-tree {
  display: block;
  max-width: 30rem;
  font-size: inherit;

  label {
    margin-bottom: 0;
    cursor: pointer;
    display: inline-flex;
    font-weight: inherit;

    &:hover {
      background: transparent;
    }

    &:active {
      background: transparent;
    }
  }
}

.rct-text {
  padding: 0.2rem 0;
}

.rct-checkbox * {
  width: 16px;
}

.rct-checkbox {
  padding: 0 0.1rem;
  display: block;
}

.rct-node-icon {
  padding: 0;
  margin: auto 0;
}

.rct-options {
  text-align: left;
  margin-left: 0;
}

.rct-option {
  opacity: 1;
  padding: 0;
}

.rct-option + .rct-option {
  margin-left: .25rem;
}

.rct-no-checkboxes {
  // Workaround to hide checkboxes

  .rct-checkbox {
    display: none;
  }

  .react-checkbox-tree label {
    cursor: default;
  }
}

.rct-title {
  align-self: center;
  padding-bottom: 1px;
}