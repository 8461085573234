@use '../../style_imports/colours' as *;

.group_roles_container {
  border: 1px solid $light_grey_border;

  margin-left: auto;
}

.group_selector_input {
  min-width: 12rem;
}

.user_field_label {
  white-space: nowrap;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
}

.user_field_name {
  max-width: 10rem;
}

.user_field {
  max-height: 1.8rem;
  font-size: small;
}

.results_container,
.overview_container {
  background-color: $grey_bg_2;
}

.download_dropdown {
  background-color: white;
  border: 1px solid $light_grey_border;
}

.action_link {
  cursor: pointer;
}

.action_link:hover {
  color: green;
}

.toggle_icon {
  cursor: pointer;
}

.error_icon {
  color: $system_error;
  width: 1.5rem;
  height: 1.5rem;
}

.wam_customer_id__not_set {
  opacity: 0.3;
}

.wam_customer_id {
  color: $dark_text
}

