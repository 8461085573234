@use '../../style_imports/colours' as *;

.spinner_wrapper {
  height: 1.5rem;
}

.actions_wrapper {
  border-bottom: 1px solid $light_grey_border;
}

.count_info {
  font-size: 14px;
  color: $grey_text_2;
}

.hero_text {
  font-size: 15px;
  font-weight: 700;
  color: $blue_grey_text_1;
}

.examples_table {
  font-size: 16px;
  color: $dark_text;
  font-weight: 400;

  tr {
    border-bottom: 1px solid $light_blue_border;
  }

  td, th {
    padding: .5rem 0;
  }
}

$details_nav_height: 3.5rem;

.details_nav_container {
  height: $details_nav_height;
  top: 0;

  background-color: $lightest_bg;
}

.details_container {
  height: calc(100% - $details_nav_height);
  padding-bottom: 1rem;
}

.family_details_top {
  position: sticky;
  top: 0;
}