// http://chir.ag/projects/name-that-color
//colour names - don't use anywhere outside of this file

$white: #fff;
$black: #000;
$cabaret: #D73F5D;
$pomegranate: #F6271C;
$midnight: #00172E;
$elephant: #132A43;
$ps_nile_blue: #18304D;
$cloud_burst: #223556;
$blue_bayoux: #485B7B;
$bali_hai: #8C9EB1;
$lynch: #687C96;
$lochmara: #006ebb;
$cerulean: #039ae5;
$picton_blue: #4FC3F7;
$sail: #BBDEFB;
$cadet_blue: #ACBAC9;
$cutty_sark: #546E7A;
$tory_blue: #0D47A1;
$boulder: #757575;
$tundora: #424242;
$wild_sand: #F5F5F5;
$ghost: #c9ced6;
$fiord: #455a64;
$hawkes_blue: #E3F2FD;
$alto: #E0E0E0;
$alto_darker: #D5D5D5;
$mine_shaft: #212121;
$tower_grey: #B0BEC5;
$big_stone: #192643;
$gorse: #FFEB3B;
$mystic_darker: #DFE6EC;
$mystic: #EAEEF2;
$porcelain_2: #ECEFF1;
$geyser: #CFD8DC;
$dodger_blue: #2196F3;
$fern: #66BB6A;
$amber: #FFC107;
$monza: #D60019;
$silver: #BDBDBD;
$sunshade: #FFA726;
$celery: #9CCC65;
$chardonnay: #FFCC80;
$outer_space: #263238;
$ps_aqua_island: #B2DFDB;
$french_lilac: #E1BEE7;
$ps_gray: #909090;
$deco: #C5E1A5;
$frangipani: #FFE0B2;
$mona_lisa: #FFAB91;
$mauvelous: #F48FB1;
$zanah: #C8E6C9;
$cold_purple: #9FA8DA;
$heather: #B7C2D0;
$rhino: #2B3B60;
$athens_grey: #FAFBFC;
$apple: #43A047;
$orange_peel: #FFA000;
$limed_spruce: #37474F;
$trinidad: #E65100;
$clementine: #EF6C00;
$sea_green: #2E7D32;
$denim: #1976D2;
$regent_gray: #78909C;
$gull_gray: #90A4AE;
$nile_blue: #1A304D;
$dove_gray: #616161;
$silver_chalice: #9E9E9E;
$gallery: #EEEEEE;
$guardsman_red: #BC0000;
$alizarin_crimson: #E8171F;
$flamingo: #F3403E;
$burnt_sienna: #EA6765;
$mauvelous: #F39390;
$pink: #FFC9CD;
$fair_pink: #FFE9EC;
$botticelli: #CAD6E1;
$alabaster: #FAFAFA;
$malibu: #64B5F6;
$aqua_forest: #73B178;
$moss_green: #A5D9A8;
$peppermint: #CCEDCE;
$zanah: #E1F1E2;
$valhalla: #311B56;
$meteorite: #45277A;
$gigas: #542F95;
$royal_purple: #6137AC;
$purple_heard: #673AB7;
$fuchsia_blue: #7E57C2;
$lilac_bush: #9575CD;
$cold_purple: #B39DDB;
$prelude: #D1C4E9;
$white_lilac: #EDE7F6;

//ps colour scheme
$ps_dark_900: $midnight;
$ps_dark_800: $elephant;
$ps_dark_700: $nile_blue;
$ps_dark_600: $cloud_burst;
$ps_dark_500: $rhino;
$ps_dark_400: $blue_bayoux;
$ps_dark_300: $lynch;
$ps_dark_200: $bali_hai;
$ps_dark_100: $cadet_blue;
$ps_dark_050: $botticelli;
$ps_dark_custom_1: $mystic;
$ps_dark_custom_2: $mystic_darker;
$ps_blue_grey_900: $outer_space;
$ps_blue_grey_800: $limed_spruce;
$ps_blue_grey_700: $fiord;
$ps_blue_grey_600: $cutty_sark;
$ps_blue_grey_500: $lynch;
$ps_blue_grey_400: $regent_gray;
$ps_blue_grey_300: $gull_gray;
$ps_blue_grey_200: $tower_grey;
$ps_blue_grey_100: $geyser;
$ps_blue_grey_050: $porcelain_2;
$ps_blue_grey_050_translucent: rgba(236, 239, 241, 0.7);
$ps_blue_900: $tory_blue;
$ps_blue_700: $denim;
$ps_blue_500: $dodger_blue;
$ps_blue_300: $malibu;
$ps_blue_100: $sail;
$ps_blue_050: $hawkes_blue;
$ps_grey_900: $mine_shaft;
$ps_grey_800: $tundora;
$ps_grey_800: $dove_gray;
$ps_grey_600: $boulder;
$ps_grey_500: $silver_chalice;
$ps_grey_400: $silver;
$ps_grey_300: $alto;
$ps_grey_200: $gallery;
$ps_grey_100: $wild_sand;
$ps_grey_050: $alabaster;
$ps_light_blue_800: $lochmara;
$ps_light_blue_600: $cerulean;
$ps_light_blue_300: $picton_blue;
$ps_red_900: $guardsman_red;
$ps_red_800: $monza;
$ps_red_700: $monza;
$ps_red_600: $alizarin_crimson;
$ps_red_500: $pomegranate;
$ps_red_400: $flamingo;
$ps_red_300: $burnt_sienna;
$ps_red_200: $mauvelous;
$ps_red_100: $pink;
$ps_red_050: $fair_pink;
$ps_orange_900: $trinidad;
$ps_orange_800: $clementine;
$ps_orange_700: $orange_peel;
$ps_orange_400: $sunshade;
$ps_yellow_orange: $amber;
$ps_orange_200: $chardonnay;
$ps_orange_100: $frangipani;
$ps_green_800: $sea_green;
$ps_green_600: $apple;
$ps_green_400: $fern;
$ps_green_300: $aqua_forest;
$ps_green_200: $moss_green;
$ps_green_100: $peppermint;
$ps_green_050: $zanah;
$ps_light_green_400: $celery;
$ps_deep_purple_900: $valhalla;
$ps_deep_purple_800: $meteorite;
$ps_deep_purple_700: $gigas;
$ps_deep_purple_600: $royal_purple;
$ps_deep_purple_500: $purple_heard;
$ps_deep_purple_400: $fuchsia_blue;
$ps_deep_purple_300: $lilac_bush;
$ps_deep_purple_200: $cold_purple;
$ps_deep_purple_100: $prelude;
$ps_deep_purple_050: $white_lilac;

// general ideas and concepts
$info: $ps_blue_500;
$success: $ps_green_400;
$system_warning: $ps_yellow_orange;
$system_error: $ps_red_700;
$attention: $ps_red_700;

$family_status_national: $ps_light_blue_300;
$family_status_inactive: $ps_grey_400;
$family_status_pending: $ps_orange_400;
$family_status_in_force: $ps_light_green_400;

$progress_not_started: $ps_grey_400;
$progress_running: $ps_orange_400;
$progress_complete: $ps_light_green_400;
$progress_failed: $ps_red_700;

//backgrounds
$dark_bg_1: $ps_dark_900;
$dark_bg_2: $ps_dark_500;
$dark_bg_3: $ps_dark_300;
$lightest_bg: $white;
$light_blue_bg_1: $ps_blue_050;
$light_blue_bg_2: $ps_dark_custom_1;
$grey_bg_1: $ps_grey_300;
$grey_bg_2: $ps_grey_100;
$blue_grey_bg_1: $ps_blue_grey_050;
$light_grey_bg: $athens_grey;

$menu_bg: $ps_dark_600;
$menu_hover: $ps_dark_400;

//texts
$lightest_text: $white;
$darkest_text: $black;
$light_blue_text: $bali_hai;
$blue_text: $ps_dark_300;
$dark_blue_text: $ps_dark_500;
$dark_text: $ps_dark_900;
$grey_text_1: $ps_grey_900;
$grey_text_2: $ps_grey_800;
$grey_text_3: $ps_grey_600;
$blue_grey_text_1: $ps_blue_grey_900;
$blue_grey_text_2: $ps_blue_grey_800;
$blue_grey_text_3: $ps_blue_grey_700;
$blue_grey_text_4: $ps_blue_grey_600;
$blue_grey_text_5: $ps_blue_grey_200;

$placeholder_text: $ps_blue_grey_200;
$grouped_btn_off_text: $ps_dark_400;

$link_text: $ps_light_blue_800;
$link_text_hover: $ps_light_blue_600;

$label_text: $blue_grey_text_3;

//homepage
$card_header: $ps_nile_blue;
$card_body: $ps_dark_800;
$card_body_hover: $big_stone;
$card_header_embellish: $cabaret;

//general, widgets
$light_grey_border: $ps_dark_100;
$dark_grey_border: $ps_dark_300;
$blue_grey_border_1: $ps_blue_grey_200;
$blue_grey_border_2: $ps_blue_grey_100;
$blue_grey_border_3: $ps_blue_grey_050;
$blue_border: $ps_blue_grey_600;
$light_blue_border: $heather;
$family_view_border: $alto_darker;
$dark_border: $ps_dark_900;
$navigation_marker: $ps_red_500;
$input_active: $ps_blue_900;
$icon_btn_bg: $ps_grey_100;
$icon_btn_bg_active: $ghost;
$btn_hover_bg: $ps_blue_050;
$highlight: $gorse;
$bright_blue_border: $ps_light_blue_300;
$inactive: $ps_blue_grey_100;
$tag_shape_background: $ps_dark_600;
$tooltip_border: $light_grey_border;
$tooltip_bg: $lightest_bg;

// disabled
$control_disabled: $ps_blue_grey_050; // background for a solid button
$control_disabled_txt: $blue_grey_text_4; // text on a solid grey ($control_disabled) disabled button
$disabled_txt: $ps_blue_grey_200; // disabled text when background is white
$disabled_button_border: $ps_blue_grey_100; // eg edge of disabled white button
$disabled_selector: $ps_dark_custom_2; // radio buttons, checkboxes
$disabled_selector_label: $ps_blue_grey_100; // labels for radio buttons, checkboxes
$disabled_tag_shape_background: $ps_blue_grey_200;

// viewer
$dataset_header: $ps_nile_blue;
$item_active: $ps_blue_900;
$light_blue_border: $ps_dark_custom_2;
$light_blue_overlay: $ps_dark_custom_1;
$filter_toggle_highlight: $ps_grey_300;
$select: $ps_green_400;
$deselect: $ps_red_500;

// ps spec group colours
$portfolio_size_spec: $ps_aqua_island;
$portfolio_trends_spec: $french_lilac;
$patenting_activity_spec: $ps_gray;
$geo_spec: $deco;
$age_spec: $frangipani;
$cost_spec: $mona_lisa;
$dispute_spec: $mauvelous;
$stats_spec: $cold_purple;
$score_spec: $zanah;
$selected_group: $ps_grey_100;
$pvix_group: $ps_blue_300;
$dark_thumbnail: $ps_dark_900;

$bright_turquoise: #0FCEC9;
$blue_chill: #0c7791;
$half_baked: #91C4D0;
$ziggurat: #B5D5DD;

//specific ag colours
$org_bg: $bright_turquoise;
$org_tag: $ps_red_700;

//specific viewers colours
$benchmarking: $ps_dark_800;
$benchmarking_mid: $ps_dark_400;
$benchmarking_light: $ps_dark_100;

//similar families page
$knn_neutral_label: $ps_dark_300;
$knn_positive_label: $ps_green_600;
$knn_negative_label: $ps_red_700;
$knn_bookmark_label: $ps_orange_700;

//admin
$admin_highlight: $frangipani;
$admin_warning: $mauvelous;

//list view
$list_item_selected: $ps_blue_grey_050;
$list_item_hover: $light_blue_bg_1;
$list_item_selected_hover: $ps_dark_custom_2;

//classifiers builder
$classifiers_sidebar: $light_grey_bg;
$classifier_label_positive: $ps_green_600;
$classifier_label_test_positive: $ps_green_600;
$classifier_label_negative: $ps_red_700;
$classifier_label_test_negative: $ps_red_700;
$classifier_label_ignore: $ps_orange_700;

$classifier_label_positive_background: $ps_green_100;
$classifier_label_test_positive_background: $ps_green_050;
$classifier_label_negative_background: $ps_red_100;
$classifier_label_test_negative_background: $ps_red_050;
$classifier_label_ignore_background: $ps_orange_100;

$classifier_label_button_hover: $ps_blue_grey_050_translucent;

//patent upload
$match_found: $ps_green_600;
$match_not_found: $ps_red_700;
$match_ambiguous: $ps_orange_700;

//ai button
$ai_btn_bg: $ps_deep_purple_500;
$ai_btn_bg_hover: $ps_deep_purple_400;
$ai_btn_bg_active: $ps_deep_purple_700;