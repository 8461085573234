@use '../../../style_imports/colours' as *;
@use '../../../style_imports/_layout' as *;

.block {
  min-height: $patent_families_list_controls_row_height;
  background-color: $lightest_bg;
  z-index: 1010; // make sure shows above table, but below top popovers (i.e. 1021) etc
}


