@use '../../style_imports/layout' as *;

.modal {
  height: 90%
}

$controls_height: 3.5rem;
$controls_padding_bottom: 1rem;

.controls {
  height: $controls_height;
  padding-bottom: $controls_padding_bottom;
}

.table {
  height: calc(100% - #{$controls_height + $controls_padding_bottom});
  overflow: auto;

  @include always_show_y_scrollbar;
}