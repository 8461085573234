@use '../../style_imports/colours' as *;

.block {
  height: 100vh;
  width: 100%;
  position: relative;
}

.breadcrumbs_wrapper {
  border-bottom: 1px solid $light_grey_border;
  background-color: $lightest_bg;
}

.breadcrumb_link {
  font-size: 18px;
}

.chevron {
  color: $blue_grey_text_4;
}

.page_name {
  font-size: 16px;
  font-weight: 700;
  color: $blue_grey_text_3;
}
