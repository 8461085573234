@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;
@use '../../style_imports/family_details_view_layout' as *;

.sticky_column {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
}

.cost_table {

  :global {
    .rt-table {
      .rt-tbody {
        overflow: visible !important;
      }
    }
  }

  @include always_show_y_scrollbar;
  &::-webkit-scrollbar {
    height: 7px;
  }

}

.header {
  background-color: $lightest_bg;
}

.menu {
  z-index: calc($label_controls_z_index - 1);
}