@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.group_info_item {
  cursor: pointer;

  &:hover {
    border-radius: $border_radius_small;
  }
}

.spec_info {
  cursor: pointer;
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}

.icon_wrapper {
  width: 1rem;
}

.nav_menu {
  background-color: $lightest_bg;
  border: 1px solid $light_grey_border;
}