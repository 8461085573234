@use '../../../style_imports/colours' as *;

.share_report_selection_controls {
  padding: 0.4rem;
}

.share_report_selection_field {
  min-width: 25rem;
}

.share_report_modal_footer {
  display: inline-flex;
}

.share_report_message_input {
  max-width: 45rem;
}

.success_message {
  margin-right: 2rem;
  color: $info;
}

.account_disabled_message {
  margin-right: 2rem;
  display: inline-flex;
}

.link {
  &:hover {
    .link_label {
      text-decoration: underline;
    }
  }
}