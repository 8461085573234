.google_search_icon_wrapper {
  .fa-stack {
    font-size: x-small;
    height: 16px;
    width: 16px;
    vertical-align: top;
  }

  .fa-circle {
    height: 16px;
    width: 16px;
  }

  .fa-google {
    height: 8px;
    width: 8px;
  }

  i {
    vertical-align: middle;
  }
}
