@import '../../style_imports/colours';

.label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  color: $label_text;
}

.label__disabled {
  color: $disabled_selector_label
}