@use '../../../style_imports/colours' as *;

.item_wrapper {
  background-color: $light_grey_bg;
}

.member_name {
  line-height: 1.5rem;
}


