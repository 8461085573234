@use '../../style_imports/colours' as *;

.grid {
  border: none !important;
  width: 100%;
  margin-bottom: 1rem;
}

.spinner_wrapper {
  height: 3rem;
}

.display_name_wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  &:hover {
    .edit_display_name_icon {
      display: inline-block;
    }
  }
}

.edit_display_name_icon {
  display: none;
  position: absolute;
  right: 0;
  background-color: rgba($lightest_bg, .8);
}

.org_search_pane {
  background-color: $lightest_bg;
  font-size: small;
  padding: 0 !important;
}

.org_search_input_container {
  background-color: $lightest_bg;
  padding: 0 !important;
  height: calc(100% - 5rem);
}

.org_search_input {
  width: 100%;
  height: 80% !important;
  overflow: scroll;
  resize: none;
}