@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

$sm_btn_dim: 1.8rem;

.btn {
  border-radius: $border_radius_small;
  height: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;

  font: 600 16px Lato !important;
}

.__primary,
.__primary:focus {
  @extend .btn;

  color: $lightest_text;
  fill: $lightest_text;
  background-color: $dark_bg_1;
  border: $btn_border_thickness solid $dark_bg_1;

  &:active {
    color: $lightest_text ;
    background-color: $dark_bg_1;
    border-color: $dark_bg_1;
  }

  &:focus {
    border-color: $bright_blue_border;
  }
}

.__primary:hover {
  color: $lightest_text;
  background-color: $dark_bg_2;
  border-color: $dark_bg_2;
}

.__prominent,
.__prominent:active,
.__prominent:focus {
  color: $lightest_text;
  border: none;
  background: linear-gradient(45deg, #DD2C00 0%, #D91B5A 100%);
}

.__prominent:hover {
  color: $lightest_text;
  border: none;
  background: linear-gradient(45deg, #F6271C 0%, #EC407A 100%);
}

.__ai:disabled,
.__prominent:disabled,
.__primary:disabled {
  background: $control_disabled !important;
  border-color: $control_disabled !important;
  color: $control_disabled_txt !important;
  opacity: 1;
  cursor: default;
}

.__ai {
  color: $lightest_text !important;
  background-color: $ai_btn_bg !important;
  border: $btn_border_thickness solid $ai_btn_bg;

  &:focus, &:focus-visible {
    background-color: $ai_btn_bg !important;
    color: $lightest_text !important;
    border-color: $bright_blue_border !important;
  }

  &:hover {
    background-color: $ai_btn_bg_hover !important;
    border-color: $ai_btn_bg_hover !important;
  }

  &:active, &:target {
    background-color: $ai_btn_bg_active !important;
    border-color: $ai_btn_bg_active !important;
  }
}

.__tertiary,
.__tertiary:disabled,
.__tertiary:focus {
  @extend .btn;

  color: $dark_text;
  fill: $dark_text;
  border: $border_thickness solid transparent;
  background-color: transparent;
  line {
    stroke: $dark_text;
  }

  &:hover {
    background-color: $blue_grey_bg_1;
  }
  &:focus {
    border-color: $bright_blue_border;
  }
}

.__tertiary:disabled {
  cursor: default;
  color: $disabled_txt;
  border-color: transparent;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    border-color: transparent;
  }
}

.__outline,
.__outline:disabled,
.__outline:focus {
  background-color: $lightest_bg;
}

.__outline:hover {
  border-color: $dark_blue_text;
  background-color: $grey_bg_2;
}

.__outline.__primary,
.__outline.__primary:hover,
.__outline.__primary:focus {
  border: $btn_border_thickness solid $dark_bg_1;
  color: $dark_text;
  fill: $dark_text;
  margin: $btn_border_thickness; // spacing for additional outline on focus
  line {
    stroke: $dark_text;
  }
}

.__outline.__primary:focus {
  outline: $btn_border_thickness solid $bright_blue_border;
}

.__outline.__primary:disabled {
  border-color: $control_disabled;
  color: $disabled_txt;
}

.__outline.__tertiary,
.__outline.__tertiary:disabled,
.__outline.__tertiary:hover,
.__outline.__tertiary:focus {
  color: $dark_text;
}

.__icon_only.__primary,
.__icon_only.__tertiary, {
  padding: 0;
  width: 2.5rem; //for default size
}

.__icon_only.__sm {
  width: $sm_btn_dim;
}

.__grouped,
.__grouped:disabled,
.__grouped:hover,
.__grouped:focus {
  // All have border left, and top/bottom borders.
  color: $grouped_btn_off_text;
  border: 1px solid $light_grey_border;
  border-right: none;
  border-radius: 0 !important;

  // Only last has border right
  &:last-child {
    border-right: 1px solid $light_grey_border;
  }
}

.__grouped__on,
.__grouped__on:disabled,
.__grouped__on:hover,
.__grouped__on:focus{
  color: $dark_text;
  background-color: $control_disabled;
}

.__xs {
  // TODO remove this? no small buttons in PS
  // Bootstrap strangely does not have a xs button class, so we add one here
  line-height: 10px;
  padding: 0 0.6rem;
  font-size: 12px;
  height: 1.6rem;

  &:hover, &:focus, &:active {
    line-height: 10px;
    padding: 0 0.6rem;
    font-size: 12px;
    height: 1.6rem;
  }
}

.__sm {
  height: $sm_btn_dim;
  padding: 0 16px;
  font-size: 14px;
  line-height: 16px;
  &:hover, &:focus, &:active  {
    height: $sm_btn_dim;
    padding: 0 16px;
    font-size: 14px;
    line-height: 16px;
  }
}

.__disabled {
  // Bootstrap/reactstrap default is disabled at 0.3 opacity
  opacity: 1 !important;
}

