@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.block {
  flex: 1;
  width: 100%;
  height: 100%;
}

.store_box {
  margin-left: $gutter_padding_xs;
  margin-right: calc($gutter_padding_xs / 2);

  @media (min-width: $sm_breakpoint) {
    margin-right: calc($gutter_padding_md / 2);
    margin-left: $gutter_padding_md;
  }

  @media (min-width: $lg_breakpoint) {
    margin-left: $gutter_padding_md;
    margin-right: $gutter_padding_sm;
  }
}

$progress_display_height: 6rem;
$progress_prompt_height: 5rem;
$basket_warning_height: 6.5rem;

.progress_display {
  height: $progress_display_height;
  padding-top: $gutter_padding_md
}

.container__store {
  background-color: $lightest_bg;
  width: 75%;
}

.container__basket {
  background-color: $lightest_bg;
  width: 25%;
}

.progress_prompt {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: auto;
  height: $progress_prompt_height;

  @include always_show_x_scrollbar;

  padding-top: 1rem;
  padding-left: $gutter_padding_xs;
  padding-right: calc($gutter_padding_xs / 2);

  @media (min-width: $sm_breakpoint) {
    padding-right: calc($gutter_padding_md / 2);
    padding-left: $gutter_padding_md;
  }

  @media (min-width: $lg_breakpoint) {
    padding-left: $gutter_padding_md;
    padding-right: $gutter_padding_sm;
  }
}

$portfolio_search_nav_height: 4rem;

.builder_container {
  height: calc(100% - #{$progress_display_height + $progress_prompt_height});
  border: 1px solid $light_grey_border;

  padding: 0 .5rem 0 1rem;
}

.portfolio_search_nav {
  position: absolute;
  top: 0;
  height: $portfolio_search_nav_height;
  z-index: 1010;
  background-color: $lightest_bg;
  width: calc(100% - .5rem);

  overflow: auto;

  @include always_show_y_scrollbar;
}

.portfolio_search_scroll_zone {
  position: absolute;
  top: $portfolio_search_nav_height;

  height: calc(100% - $portfolio_search_nav_height);
  overflow-x: auto;
  width: 100%;
  @include always_show_y_scrollbar;
}

.technology_scroll_zone {
  overflow-x: auto;
  width: 100%;
  @include always_show_y_scrollbar;
}

.basket_box {
  margin: 0 $gutter_padding_xs 0 calc($gutter_padding_xs / 2);

  @media (min-width: $sm_breakpoint) {
    margin-right: $gutter_padding_md;
    margin-left: calc($gutter_padding_md / 2);
  }

  @media (min-width: $lg_breakpoint) {
    margin-right: $gutter_padding_md;
    margin-left: $gutter_padding_sm;
  }
}

.basket {
  margin-top: $gutter_padding_md;
  height: calc(100% - #{2 * $gutter_padding_md});
}

.basket__with_warning {
  height: calc(100% - #{$basket_warning_height + 3 * $gutter_padding_md});
}

.basket_warning {
  position: absolute;
  bottom: $gutter_padding_md;
  height: $basket_warning_height;
  overflow: auto;
  @include always_show_y_scrollbar;
}
