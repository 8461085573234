@use '../../../style_imports/layout' as *;
@use '../../../style_imports/colours' as *;

.dropdown_toggle {
  color: $benchmarking;
  background-color: $lightest_bg;
  text-align: center;
  font-size: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid $lightest_bg;
  border-radius: 4px;
  cursor: pointer;

  width: 3rem;

  @media (min-width: $sm_breakpoint) {
    width: 6rem;
  }

  @media (min-width: $md_breakpoint) {
    width: 9rem;
  }

  @media (min-width: $lg_breakpoint) {
    width: 11rem;
  }

  &:focus {
    box-shadow: none;
  }
}

.dropdown_toggle:hover,
.dropdown_toggle:focus,
.dropdown_toggle__open,
.dropdown_toggle__open:hover {
  text-shadow: .5px 0 0 $benchmarking;
  text-decoration: none;
}

.dropdown_menu {
  border-radius: 0;
  border: 1px solid $light_grey_border;
  font-size: .85rem;
  width: 18rem;
}

.dropdown_menu:focus {
  outline: none;
}

.spotlight_control {
  display: none;
}

.option_wrapper {
  &:hover {
    .spotlight_control {
      display: block;
    }
  }
}

.dropdown_item {
  &:hover, &:focus {
    background-color: transparent;
  }
}