@use '../../style_imports/colours' as *;

.block {
  word-break: break-word;
}

.description_wrapper__visible {
  border-bottom: 2px solid $family_view_border;

  & img {
    max-width: 100%;
  }
}

