@use '../../style_imports/colours' as *;

.recent_changes_block {
  font-size: small;
  min-width: 10rem;
}

.more_changes_block {
  font-size: smaller;
  color: $grey_text_1;
}