@use '../../style_imports/_layout' as *;
@use '../../style_imports/colours' as *;

.controls_row {
  position: sticky;
  top: 0;
  z-index: 1010;
}

.family_details_controls_row {
  position: sticky;
  top: 0;
}

.table_header {
  position: sticky;
  top: $patents_container_header_lg_height;
}

.view_selector {
  border-right: 1px solid $blue_grey_border_3;
  width: 56px;
}

.disputes_wrapper {
  height: calc(100% - 3rem);
  overflow: auto;
}

.item_wrapper {
  height: calc(100% - 3rem);
  overflow: hidden;
}

.chart_wrapper {
  margin: .25rem 1rem;
  width: calc(100% - 3rem);
  overflow-y: auto;

  @include always_show_y_scrollbar;
}

.list_wrapper {
  margin: .5rem 0;
  height: calc(100% - (3rem + 1rem));
  overflow: auto;

  @include always_show_y_scrollbar;
}

