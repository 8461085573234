@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

.block {
  position: relative;
  display: inline-block;
  cursor: pointer;
  max-height: 40px;

  font-size: 16px;
  font-weight: 400;
  //line-height: 24px;

  color: $dark_text;

  border: $border_thickness solid $light_grey_border;
  border-radius: $border_radius_small;
  background-color: $lightest_bg;

  padding: .5rem;

  &:hover {
    border-color: $dark_grey_border;
  }

  &:active {
    border-color: $bright_blue_border;
    outline: 1px solid rgba(79, 195, 247, .40);
  }
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.__disabled  {
  color: $disabled_txt;
  border-color: $disabled_button_border !important;
  outline: none !important;
  cursor: default;
}

