@use '../style_imports/colours' as *;

.font_weight_normal {
  font-weight: 400 !important;
}

.font_weight_bold {
  font-weight: 700 !important;
}

.font_size_x_small {
  font-size: 12px !important;
}

.font_size_smaller {
  font-size: smaller !important;
}

.font_size_small {
  font-size: 14px !important;
}

.font_size_medium {
  font-size: 16px !important;
}

.font_size_large {
  font-size: 18px !important;
}

.no_outline {
  outline: none !important;
  &:focus, &:focus-within {
    outline: none !important;
  }
}

.white_text {
  color: $lightest_text !important;
}

.dark_grey_text {
  color: $grey_text_1;
}

.link_text {
  color: $link_text;
  &:hover {
    color: $link_text_hover;
  }
}

.disabled_text {
  color: $disabled_txt !important;
}

.white_bg {
  background-color: $lightest_bg !important;
}

.pointer_events_disabled {
  pointer-events: none !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.cursor_default {
  cursor: default !important;
}

.text_transform_none {
  text-transform: none;
}

.white_space_nowrap {
  white-space: nowrap;
}

.white_space_wrap {
  white-space: wrap !important;
}

.border_none {
  border: none !important;
}

.small_button {
  height: 1.5rem;
}

.bg_transparent {
  background-color: transparent !important;
}

.no_underline_on_hover {
  &:hover {
    text-decoration: none !important;
  }
}

.underline_on_hover {
  &:hover {
    text-decoration: underline !important;
  }
}

.rotate_90 {
  rotate: 90deg;
}

.rotate_180 {
  rotate: 180deg;
}

.in_table_input {
  height: 1.8rem;
  font-size: 15px;
  padding: 0 .25rem;
}

.overflow_x_scroll {
  overflow-x: scroll;
}

.overflow_y_scroll {
  overflow-y: scroll;
}

.text_overflow_ellipsis {
  text-overflow: ellipsis;
}

.overflow_unset {
  overflow: unset !important;
}

.resize_disabled {
  resize: none;
}

.classifier_positive_bg {
  background-color: $classifier_label_positive_background !important;
}

.classifier_test_positive_bg {
  background-color: $classifier_label_test_positive_background !important;
}

.classifier_negative_bg {
  background-color: $classifier_label_negative_background !important;
}

.classifier_test_negative_bg {
  background-color: $classifier_label_test_negative_background !important;
}

.classifier_ignore_bg {
  background-color: $classifier_label_ignore_background !important;
}
