@use '../style_imports/colours' as *;

.page_container {
  display: flex; // Act as Flex context for (header, content, footer)
  flex-direction: column;
  background-color: $lightest_bg;
  overflow: auto;
}

.page_dark_bg {
  background-color: $dark_bg_1;
}