@use '../../style_imports/_layout' as *;
@use '../../style_imports/viewer_layout' as *;

.block__thumbnail {
  overflow: auto;
  height: $thumbnail_large_height;
  min-height: $thumbnail_large_height;
}

.no_jumping{
  min-height: 1.5rem;
}

.family_details_item_header {
  z-index: 1010;
  height: 3rem;
  top: calc($header_height_xl + $report_controls_bar_height);
}