@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.save_btn {
  width: 7rem;
}

.msg_container {
  height: 2rem;
}

.select_as_default {
  display: none;

  .chart_selection:hover & {
    display: block;
  }
}

.selected_as_default {
  font-size: smaller;
  color: $grey_text_1;
}
