@use '../../style_imports/colours' as *;
@use '../../style_imports/layout' as *;

.menu {
  min-width: 4rem;
}

.dropdown_button {
  border: $border_thickness solid $dark_border;
  padding-top: 3px !important;     // workaround to prevent buttons from changing height of header
  padding-bottom: 3px !important;

  background-color: $lightest_bg;
  color: $dark_text;
}