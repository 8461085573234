@use '../../style_imports/layout' as *;
@use '../../style_imports/colours' as *;

$base_top_sm: $header_height_xl;
$base_top: calc($header_height_xl + $knn_input_summary_row_height + $knn_input_controls_row_height);

$sticky_z_index: 1010;

.controls_row {
  background-color: $lightest_bg;
  min-height: $patent_families_list_controls_row_height;

  @media (min-width: $md_breakpoint) {
    position: sticky;
    z-index: $sticky_z_index;
    top: $base_top;
  }
}

.controls {
  height: 3rem;
}

.table_header {
  position: sticky;
  top: $base_top_sm;
  z-index: calc($sticky_z_index - 1);
  background-color: $lightest_bg;
  @media (min-width: $md_breakpoint) {
    top: calc($base_top + $patent_families_list_controls_row_height);
  }
}

.details_view_wrapper {
  height: calc(100% - $header_height_xl);
}

$details_nav_height: 3.5rem;

.details_nav_container {
  height: $details_nav_height;
  top: $header_height_xl;

  background-color: $lightest_bg;
}

.details_container {
  height: calc(100vh - ($header_height_xl + $details_nav_height));
  padding-bottom: 1rem;
}

.family_details_controls_row {
  top: 0;
}

.boolean_search_input {
  height: 2.1rem !important;
}

.boolean_search_input_clear_btn {
  top: 0.25rem !important;
}

.list_actions_wrapper {
  border: none !important;
}

$toolbar_height: 3.5rem;

.results_wrapper {
  margin-bottom: calc($toolbar_height + 1rem);
}

.toolbar {
  height: $toolbar_height;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $lightest_bg;
  border-top: 1px solid $blue_grey_border_2;
  z-index: $sticky_z_index;

  box-shadow: 0 -.25rem 1rem 0 rgba($dark_bg_1, 0.15)
}

.download_spinner_wrapper {
  width: 1.5rem;
}

.quick_report_link {
  border-radius: $border_radius_small;
  color: $lightest_text;
  background-color: $dark_bg_1;
  height: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    color: $lightest_text;
    background-color: $dark_bg_1;
    text-decoration: none;
  }
}

.actions_menu {
  width: 14rem;
}

.custom_action {
  color: $blue_grey_text_3;
  &:hover {
    text-decoration: none;
    color: $blue_grey_text_3;
  }
}

.results_export_btn {
  height: 2.5rem;
}
