@use '../../../style_imports/colours' as *;

.popover_frame {
  max-width: 6rem;
  position: relative;
}

.popover_content {
  font-size: 12px;
}

.spotlight_control {
  color: $attention;
}