@use '../../style_imports/colours' as *;

.date_wrapper {
  width: 5.5rem;
  min-width: 5.5rem;
}

.highlight {
  font-weight: 600;
}

.top_border {
  border-top: 1px solid $family_view_border;
}

.top_border__grouped {
  border-top: 1px dotted $family_view_border;
}
