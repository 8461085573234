.clickable_icon {
  padding-left: 0.1rem;
  padding-right: 0.2rem;
}

.edit_text_input {
  max-height: 2rem;
  font-size: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  margin-right: 0.2rem;
  background-image: none !important;
}