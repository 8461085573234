.heading {
  font-weight: bolder;
}

.labels {
  border: 0;
}

.tag_group_row {
  height: 2rem;
}

.position_dropdown_button {
  border: none;
  outline: none !important;
}

.position_dropdown {
  min-width: 4.5rem !important;
}