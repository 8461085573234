@use '../../style_imports/layout' as *;
@use '../../style_imports/_viewer_layout' as *;

$margin: .5rem;

.report_content_block {
  margin: $margin 0;
  height: calc(100% - ($data_container_sticky_top_starting_point + (2 * $margin) + .125rem));

  padding-left: .5rem;
  padding-right: 0;
}

.groups_view {
  overflow: auto;

  @include always_show_y_scrollbar;
}
