@use '../../style_imports/colours' as *;

.monitor_report_icons {
  font-size: 1.2rem;
  margin-top: 0.3rem;
}

.status_waiting {
  color: $light_blue_text;
}

.status_started {
  color: $progress_running;
}

.status_failed {
  color: $progress_failed;
}

.failed_report_info {
  border: 0.2rem solid $progress_failed;
}

.end_time_info {
  background-color: $ps_dark_100;
  color: $lightest_text;
  margin-top: 1rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.task_block {
  margin-left: 2rem;
}

.translated_input {
  border-left: 0.1rem dotted $blue_text;
  padding-left: 0.3rem;
}

.report_input_label {
  margin-bottom: 0.2rem;
}