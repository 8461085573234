@use '../../style_imports/colours' as *;

.tree_control {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    outline: none;
  }
}

.tree_control_icon {
  @extend .tree_control;

  color: $input_active;
}