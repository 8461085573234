@use '../../style_imports/colours' as *;

.node_disabled {
  span {
    color: $control_disabled_txt;
    cursor: default;
    border-color: $control_disabled;

    &:after {
      border-color: $control_disabled;
    }
  }
}

.label {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: $label_text;
  display: inline !important;
}

.label__spotlighted {
  text-shadow: .5px 0 0 $darkest_text;
  color: $label_text;
}

.desc_popup {
  max-height: 25rem;
  overflow-y: auto;
}