@use '../../style_imports/colours' as *;

.address_wrapper {
  word-wrap: break-word;
}

.icon {
  color: $dark_text;
}

.info {
  font-size: 12px;
  font-weight: 700;
  color: $grey_text_2;
  padding-top: .20rem;
}

.inactive {
  color: $blue_text;
}