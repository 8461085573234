@use '../../style_imports/colours' as *;

.breadcrumbs_bar {
  height: 2.75rem;
  font-size: larger;
  color: $dark_text;
}

.breadcrumbs_separator {
  color: $dark_text;
}
