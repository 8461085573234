@use '../../style_imports/colours' as *;

.heading {
  font-size: 15px;
  font-weight: 700;

  color: $blue_grey_text_3;
}
.item {
  font-size: 15px;
  font-weight: 400;
  color: $blue_grey_text_3;
  border-bottom: 1px solid $blue_grey_border_1;
  padding-bottom: .5rem;
  &:last-child {
    border-bottom: none;
  }
}